import { useState } from 'react';
import { Box, Select, Text, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';

import Column from 'components/Column';
import Row from 'components/Row';
import Loader from 'components/Loader';
import LifestyleResultsItem from './LifestyleResultsItem';
import { useAppSelector } from 'hooks';
import kbSlice from 'slices/kb';
import { SORT_OPTIONS } from 'util/constants';



export default function LifestyleResults() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [cursor, setCursor] = useState(20);
  const PAGE_LENGTH = 20;
  const results = useAppSelector((state) => state.kb.results);
  const page = useAppSelector((state) => state.kb.page);
  const total = useAppSelector((state) => state.kb.total);
  const sort = useAppSelector((state) => state.kb.sort);

  function loadLifestyles() {
    dispatch(kbSlice.actions.setPage(page + 1));
    setCursor(cursor + PAGE_LENGTH);
  }

  function handleSortChange(option: string) {
    dispatch(kbSlice.actions.setSort(option));
  }

  const lifestyles = results.slice(0, cursor);

  return (
    <Column className={classes.container}>
      <Row className={classes.header}>
        <Text size="lg" weight={600}>
          {total} conditions found
        </Text>
        <Select
          className={classes.sort}
          size="sm"
          placeholder="Sort by..."
          value={sort}
          data={SORT_OPTIONS}
          onChange={handleSortChange}
        />
      </Row>
      <Box id="results" className={classes.results}>
        <InfiniteScroll
          dataLength={lifestyles.length}
          next={loadLifestyles}
          hasMore={lifestyles.length < total}
          loader={<Loader />}
          scrollableTarget="results"
        >
          {lifestyles.map((item: any) => (
            <LifestyleResultsItem key={item._id} {...item} />
          ))}
        </InfiniteScroll>
      </Box>
    </Column>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: 'fit-content',
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  sort: {
    input: {
      width: '120px',
      fontWeight: 600,
      color: theme.colors.gray[6],
      border: 'none',
      '&:hover': {
        background: theme.colors.gray[1],
      },
    },
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '1409px',
    overflow: 'auto',
  },
}));
