import React from 'react';
import { Card as MantineCard, createStyles } from '@mantine/core';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Card({ children, className }: Props) {
    const { classes, cx } = useStyles();

    return (
        <MantineCard className={cx(classes.container, className)} padding="lg">
            {children}
        </MantineCard>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        background: 'white',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.gray[3]}`,
    },
}));
