import { createSlice } from '@reduxjs/toolkit';
import { Version } from 'types/kb';

export interface KbState {
    search: string;
    version: string;
    loading: boolean;
    page: number;
    total: number;
    sort: string;
    filters: any;
    results: any;
}

const initialState: KbState = {
    search: '',
    version: Version.v2,
    loading: false,
    page: 1,
    total: 0,
    sort: '',
    filters: {
        papers: {
            status: [],
            organisms: [],
            conditions: [],
            diets: [],
            symptoms: [],
            treatments: [],
            sensitivities: [],
            stool: false,
            microbiome: true,
            annotated: false,
            flagged: false,
            subjectType: [],
            testType: [],
            paperType: [],
            studyType: [],
            sequencingType: [],
        },
        conditions: {
            is_display: false,
        },
        symptoms: {
            is_display: false,
        },
        sensitivities: {
            is_display: false,
        },
        diets: {
            is_display: false,
        },
        treatments: {
            is_display: false,
        },
        metabolisms: {
            is_display: false,
        },
        organisms: {
            // is_display: true,
        },
        lifestyles: {
            is_display: false,
        }
    },
    results: [],
};

const kbSlice = createSlice({
    name: 'kb',
    initialState,
    reducers: {
        setVersion: (state, action) => {
            state.version = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setSort: (state, action) => {
            let sort = '';

            switch (action.payload) {
                case 'Updated Date':
                    sort = 'updatedAt'
                    break;
                case 'Creation Date':
                    sort = 'createdAt'
                    break;
                case 'A-Z':
                    sort = 'alphaAsc'
                    break;
                case 'Z-A':
                    sort = 'alphaDesc'
                    break;
                case 'Score':
                    sort = 'score'
                    break;
                case 'Published Date':
                    sort = 'publishedAt';
                    break;
                default:
                    sort = '';
                    break;
            }

            state.sort = sort;
        },

        updatePaperFilter: (state, action) => {
            state.filters.papers[action.payload.filter] = action.payload.value;
        },
        clearPaperFilters: (state) => {
            state.filters.papers = initialState.filters.papers;
        },

        updateConditionFilter: (state, action) => {
            state.filters.conditions[action.payload.filter] =
                action.payload.value;
        },
        clearConditionFilters: (state) => {
            state.filters.conditions = initialState.filters.conditions;
        },

        updateSymptomFilter: (state, action) => {
            state.filters.symptoms[action.payload.filter] =
                action.payload.value;
        },
        clearSymptomFilters: (state) => {
            state.filters.symptoms = initialState.filters.symptoms;
        },

        updateSensitivityFilter: (state, action) => {
            state.filters.sensitivities[action.payload.filter] =
                action.payload.value;
        },
        clearSensitivityFilters: (state) => {
            state.filters.sensitivities = initialState.filters.sensitivities;
        },

        updateDietFilter: (state, action) => {
            state.filters.diets[action.payload.filter] = action.payload.value;
        },
        clearDietFilters: (state) => {
            state.filters.diets = initialState.filters.diets;
        },

        updateTreatmentFilter: (state, action) => {
            state.filters.treatments[action.payload.filter] =
                action.payload.value;
        },
        clearTreatmentFilters: (state) => {
            state.filters.treatments = initialState.filters.treatments;
        },

        updateMetabolismFilter: (state, action) => {
            state.filters.metabolisms[action.payload.filter] =
                action.payload.value;
        },
        clearMetabolismFilters: (state) => {
            state.filters.metabolisms = initialState.filters.metabolisms;
        },

        updateOrganismFilter: (state, action) => {
            state.filters.organisms[action.payload.filter] =
                action.payload.value;
        },
        clearOrganismFilters: (state) => {
            state.filters.organisms = initialState.filters.organisms;
        },

        updateLifestyleFilter: (state, action) => {
            state.filters.lifestyles[action.payload.filter] =
                action.payload.value;
        },
        clearLifestyleFilters: (state) => {
            state.filters.lifestyles = initialState.filters.lifestyles;
        },
    },
});

export default kbSlice;
