// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import {
    Box,
    TextInput,
    Select,
    MultiSelect,
    Checkbox,
    Button,
    createStyles,
} from '@mantine/core';
import { useDispatch } from 'react-redux';
import debounce from 'debounce';

import Loader from 'components/Loader';
import Label from 'components/Label';
import kbApi from 'services/kb';
import { itemsToDropdown, organismsToDropdown } from 'util/transforms';
import kbSlice from 'slices/kb';
import { useAppSelector } from 'hooks';
import { INSIGHT_TYPES } from 'util/constants';


export default function PaperFilter() {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const filters = useAppSelector((state) => state.kb.filters.papers);
    const version = useAppSelector((state) => state.kb.version);

    const [organismCache, setOrganismCache] = useState([]);
    const [organismSearch, setOrganismSearch] = useState('');
    const debouncedOrganismSearch = useDebouncedValue(organismSearch, 500);

    const { data: organismData, isLoading: isOrganismsLoading } =
        kbApi.endpoints.getOrganisms.useQuery(
            {
                name: debouncedOrganismSearch,
                version
            },
            { refetchOnMountOrArgChange: true }
        );
    const { data: organismFilterData, isLoading: isOrganismsFilterLoading } =
        kbApi.endpoints.getOrganismsFromIds.useQuery({
            ids: filters.organisms,
            version
        });
    const { data: conditionData, isLoading: isConditionsLoading } =
        kbApi.endpoints.getConditions.useQuery({ version });
    const { data: symptomData, isLoading: isSymptomsLoading } =
        kbApi.endpoints.getSymptoms.useQuery({ version });
    const { data: dietData, isLoading: isDietsLoading } =
        kbApi.endpoints.getDiets.useQuery({ version });
    const { data: treatmentData, isLoading: isTreatmentsLoading } =
        kbApi.endpoints.getTreatments.useQuery({ version });
    const { data: sensitivityData, isLoading: isSensitivitiesLoading } =
        kbApi.endpoints.getSensitivities.useQuery({ version });

    useEffect(() => {
        if (organismData && organismFilterData) {
            const uniqueOrganisms = new Set();
            let tempOrganisms = [...organismCache];
            tempOrganisms.push(
                ...organismsToDropdown(organismData),
                ...organismsToDropdown(organismFilterData)
            );
            tempOrganisms = tempOrganisms.filter((organism: any) => {
                if (!uniqueOrganisms.has(organism.value)) {
                    uniqueOrganisms.add(organism.value);
                    return true;
                }
                return false;
            });
            setOrganismCache(tempOrganisms);
        }
    }, [organismData, organismFilterData]);

    const loading =
        isOrganismsLoading ||
        isOrganismsFilterLoading ||
        isConditionsLoading ||
        isSymptomsLoading ||
        isTreatmentsLoading ||
        isSensitivitiesLoading ||
        isDietsLoading;
    if (loading) {
        return (
            <Box className={classes.container}>
                <Box className={classes.card}>
                    <Loader />
                </Box>
            </Box>
        );
    }

    const organisms = itemsToDropdown(organismData);
    const conditions = itemsToDropdown(conditionData);
    const symptoms = itemsToDropdown(symptomData);
    const diets = itemsToDropdown(dietData);
    const treatments = itemsToDropdown(treatmentData);
    const sensitivities = itemsToDropdown(sensitivityData);

    function useDebouncedValue(value: any, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value);

        const debounced = useCallback(
            debounce((nextValue: any) => setDebouncedValue(nextValue), delay),
            [delay]
        );

        React.useEffect(() => {
            debounced(value);
            return debounced.cancel;
        }, [value, debounced]);

        return debouncedValue;
    }

    const handleTextFilterChange = (filter: string) => (event: any) => {
        dispatch(
            kbSlice.actions.updatePaperFilter({
                filter,
                value: event.target.value,
            })
        );
    };
    const handleSelectFilterChange = (filter: string) => (data: any) => {
        dispatch(
            kbSlice.actions.updatePaperFilter({
                filter,
                value: data,
            })
        );
    };
    const handleCheckboxFilterChange = (filter: string) => (event: any) => {
        dispatch(
            kbSlice.actions.updatePaperFilter({
                filter,
                value: event.currentTarget.checked,
            })
        );
    };

    function handleClearClick() {
        dispatch(kbSlice.actions.clearPaperFilters());
    }

    return (
        <Box className={classes.container}>
            {/* <TextInput
                label="Paper ID"
                placeholder="Enter paper ID..."
                value={filters.paperId}
                onChange={handleTextFilterChange('paperId')}
            /> */}

            <Box className={classes.card}>
                <Label>Options</Label>
                {/* <Checkbox
                    disabled
                    mt="xs"
                    label="Annotated"
                    size="sm"
                    checked={filters.annotated}
                    onChange={handleCheckboxFilterChange('annotated')}
                /> */}
                <Checkbox
                    mt="sm"
                    label="Flagged"
                    size="sm"
                    checked={filters.flagged}
                    onChange={handleCheckboxFilterChange('flagged')}
                />
                <Checkbox
                    mt="sm"
                    label="Microbiome Related"
                    size="sm"
                    checked={filters.microbiome}
                    onChange={handleCheckboxFilterChange('microbiome')}
                />
                <MultiSelect
                    mt="lg"
                    clearable
                    label="Status"
                    placeholder="Select status"
                    data={['not started', ...INSIGHT_TYPES.STATUSES]}
                    value={filters.status}
                    onChange={handleSelectFilterChange('status')}
                />
                {/* <Checkbox
                    mt="md"
                    label="Stool Related"
                    size="sm"
                    checked={filters.stool}
                    onChange={handleCheckboxFilterChange('stool')}
                />
                <Checkbox
                    mt="md"
                    label="Microbiome Related"
                    size="sm"
                    checked={filters.microbiome}
                    onChange={handleCheckboxFilterChange('microbiome')}
                /> */}
                <Button
                    fullWidth
                    mt="lg"
                    variant="light"
                    onClick={handleClearClick}
                >
                    Clear Filters
                </Button>
            </Box>

            <Box className={classes.card}>
                <MultiSelect
                    label="Organisms"
                    placeholder="Select organisms"
                    searchable
                    data={organismCache}
                    limit={20}
                    value={filters.organisms}
                    searchValue={organismSearch}
                    onSearchChange={(value) => setOrganismSearch(value)}
                    onChange={handleSelectFilterChange('organisms')}
                />
                <MultiSelect
                    mt="lg"
                    label="Conditions"
                    placeholder="Select conditions"
                    searchable
                    data={conditions}
                    value={filters.conditions}
                    onChange={handleSelectFilterChange('conditions')}
                />
                <MultiSelect
                    mt="lg"
                    label="Symptoms"
                    placeholder="Select symptoms"
                    searchable
                    data={symptoms}
                    value={filters.symptoms}
                    onChange={handleSelectFilterChange('symptoms')}
                />
                <MultiSelect
                    mt="lg"
                    label="Diets"
                    placeholder="Select diets"
                    searchable
                    data={diets}
                    value={filters.diets}
                    onChange={handleSelectFilterChange('diets')}
                />
                <MultiSelect
                    mt="lg"
                    label="Treatments"
                    placeholder="Select treatments"
                    searchable
                    data={treatments}
                    value={filters.treatments}
                    onChange={handleSelectFilterChange('treatments')}
                />
                <MultiSelect
                    mt="lg"
                    label="Sensitivities"
                    placeholder="Select sensitivities"
                    searchable
                    data={sensitivities}
                    value={filters.sensitivities}
                    onChange={handleSelectFilterChange('sensitivities')}
                />
            </Box>

            <Box className={classes.card}>
                <MultiSelect
                    label="Subject Type"
                    placeholder="Select subject type"
                    searchable
                    data={INSIGHT_TYPES.SUBJECT_TYPES}
                    value={filters.subjectType}
                    onChange={handleSelectFilterChange('subjectType')}
                />
                <MultiSelect
                    mt="lg"
                    label="Test Type"
                    placeholder="Select test type"
                    searchable
                    data={INSIGHT_TYPES.TEST_TYPES}
                    value={filters.testType}
                    onChange={handleSelectFilterChange('testType')}
                />
                {/* <MultiSelect
                    mt="lg"
                    label="Paper Type"
                    placeholder="Select test type"
                    searchable
                    data={INSIGHT_TYPES.PAPER_TYPES}
                    value={filters.paperType}
                    onChange={handleSelectFilterChange('paperType')}
                /> */}
                <MultiSelect
                    mt="lg"
                    label="Study Type"
                    placeholder="Select test type"
                    searchable
                    data={INSIGHT_TYPES.STUDY_TYPES}
                    value={filters.studyType}
                    onChange={handleSelectFilterChange('studyType')}
                />
                <MultiSelect
                    mt="lg"
                    label="Sequencing Type"
                    placeholder="Select test type"
                    searchable
                    data={INSIGHT_TYPES.SEQUENCING_TYPES}
                    value={filters.sequencingType}
                    onChange={handleSelectFilterChange('sequencingType')}
                />
            </Box>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '400px',
        maxWidth: '400px',
        maxHeight: '80vh',
        marginRight: theme.spacing.lg,
        marginBottom: theme.spacing.xl,
        overflow: 'auto',
    },
    card: {
        padding: theme.spacing.lg,
        marginBottom: theme.spacing.lg,
        background: 'white',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
    },
}));
