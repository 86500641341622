import React from 'react';
import { createStyles, Loader, Box } from '@mantine/core';

export default function Callback() {
    const { classes } = useStyles();

    return (
        <Box mx="auto" className={classes.container}>
            <Loader variant="dots" />
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '256px',
    },
}));
