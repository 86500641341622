import React, { useEffect, useRef, useState } from 'react';
import {
  Affix,
  createStyles,
  TextInput,
  Badge,
  Button,
  Grid,
  Checkbox,
  Select,
  Flex,
  Box,
  Text,
  MultiSelect,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import Label from 'components/Label';
import Loading from 'pages/Loading';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import { admins } from 'util/constants';
import { useAppSelector } from 'hooks';
import { BulletPointDescriptionsForm } from 'components/SharedForms/BulletPointDescriptionsForm';
import { GeneralDescriptionsForm } from 'components/SharedForms/GeneralDescriptionsForm';
import { DescriptionList } from 'types/kb/prerelease';
import { cloneDeep } from 'lodash';
import MetaHeader from 'components/SharedForms/MetaHeader';
import { useNavigate, useParams } from 'react-router-dom';

const CATEGORIES = [
  'Dermatological Disorder',
  'Cardiovascular Disorder',
  'Neurological Disorder',
  'Gastrointestinal Disorder',
  'Endocrine Disorder',
  'Neurodevelopmental Disorder',
  'Mental Health Disorder',
  'Allergic Disorder',
  'Neurodegenerative Disorder',
  'Neuromuscular Disorder',
  'Autoimmune Disorder',
  'Musculoskeletal Disorder',
  'Respiratory Disorder',
  'Metabolic Disorder',
  'Inflammatory Disorder',
  'Oncological Disorder',
  'Renal Disorder',
  'Immunological Disorder',
  'Genetic Disorder',
  'Geriatric Disorder',
  'Reproductive Disorder',
  'Rheumatological Disorder',
  'Hematological Disorder',
  'Neurocognitive Disorder',
  'Infectious Disorder',
  'Urological Disorder',
  'Genitourinary Disorder',
];

export default function ConditionForm(item: any) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const formRef = useRef(null);
  const version = useAppSelector((state) => state.kb.version);
  const { conditionId } = useParams();
  const [isCreating, setIsCreating] = useState(conditionId === 'new');

  let initialValues = {
    name: '',
    abbreviation: '',
    preferred_name: '',
    mesh_id: '',
    symptoms: [],
    keywords: [],
    description: '',
    one_line_description: '',
    bullet_point_description: [] as DescriptionList[],
    is_display: false,
should_recommend: false,
    category: '',
    relations: {
      causes: {
        symptoms: [],
      },
      treated_by: {
        treatments: [],
      },
      is_subtype_of: {
        conditions: [],
      },
      is_supertype_of: {
        conditions: [],
      },
    },
  };

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (!item._id) {
      form.setValues(initialValues);
    } else {
      form.setValues({
        name: item.name,
        abbreviation: item.abbreviation,
        preferred_name: item.preferred_name,
        mesh_id: item.mesh_id,
        symptoms: item.symptoms,
        keywords: item.keywords,
        description: item.description,
        one_line_description: item.one_line_description,
        bullet_point_description: cloneDeep(item.bullet_point_description) ?? [] as DescriptionList[],
        is_display: item.is_display,
        should_recommend: item.should_recommend,
        category: item.category,
        relations: {
          causes: {
            symptoms: item.relations.causes.symptoms,
          },
          treated_by: {
            treatments: item.relations.treated_by.treatments,
          },
          is_subtype_of: {
            conditions: item.relations.is_subtype_of.conditions,
          },
          is_supertype_of: {
            conditions: item.relations.is_supertype_of.conditions,
          },
        },
      });
    }
  }, []);


  const { data: symptomData, isLoading: isSymptomsLoading } = kbApi.endpoints.getSymptoms.useQuery({ version });
  const { data: conditionData, isLoading: isConditionsLoading } = kbApi.endpoints.getConditions.useQuery({ version });
  const { data: treatmentData, isLoading: isTreatmentsLoading } = kbApi.endpoints.getTreatments.useQuery({ version });
  const [updateCondition, { isLoading }] = kbApi.endpoints.updateCondition.useMutation();

  const loading = isSymptomsLoading || isConditionsLoading || isTreatmentsLoading;
  if (loading) {
    return <Loading />;
  }

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateCondition({
      id: item._id || 'new',
      version: version,
      ...values,
    });
    await item.refetch();

    if (isCreating) {
      navigate(`/kb/${version}/conditions`);
    }
  }

  function handleCreateKeyword(keyword: string) {
    form.insertListItem('keywords', keyword);
  }

  const symptoms = itemsToDropdown(symptomData);
  const treatments = itemsToDropdown(treatmentData);
  const conditions = itemsToDropdown(conditionData);

  return (
    <form ref={formRef} className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Grid.Col span={2} order={2}>
         <MetaHeader
            collectionName="Condition"
            createdAt={item.createdAt}
            updatedAt={item.updatedAt}
            // @ts-ignore
            form={form}
            saveCallback={form.onSubmit(handleSubmit)}
          />
          </Grid.Col>
        <Grid.Col span={10} order={1}>
          <Box p="lg" className={classes.container} miw={{ base:'550px', md: '800px'}}>
            <Box className={classes.section}>
              <Flex justify="space-between" align="center">
                <Text size="lg" mb="md">
                  Details
                </Text>
                <Badge radius="sm" variant="filled" size="lg">
                  {version}
                </Badge>
              </Flex>

              <Flex>
                <TextInput
                  className={classes.fieldCol}
                  label="Name"
                  placeholder="Enter the name..."
                  {...form.getInputProps('name')}
                />
                <TextInput ml="xl" className={classes.fieldCol} label="ID" disabled value={item._id} />
              </Flex>
              <Flex mt="md">
                <Select
                  className={classes.fieldCol}
                  label="Category"
                  placeholder="Enter the category..."
                  data={CATEGORIES}
                  {...form.getInputProps('category')}
                />
                <TextInput
                  ml="xl"
                  className={classes.fieldCol}
                  label="Abbreviation"
                  placeholder="Enter the abbreviation..."
                  {...form.getInputProps('abbreviation')}
                />
              </Flex>
              <Flex mt="md">
                <TextInput
                  className={classes.fieldCol}
                  label="Preferred name"
                  placeholder="Enter the preferred name..."
                  {...form.getInputProps('preferred_name')}
                />
                <TextInput ml="xl" className={classes.fieldCol} label="MeSH ID" {...form.getInputProps('mesh_id')} />
              </Flex>
            </Box>

            <Box className={classes.section}>
              <Flex direction="column" gap="lg">
                <GeneralDescriptionsForm 
                // @ts-ignore
                form={form} />

                <Label>Bulleted Description List</Label>
                <BulletPointDescriptionsForm
                // @ts-ignore
                form={form} />
              </Flex>
            </Box>

            <Box className={classes.section}>
              <Text size="lg" mb="md">
                Relationships
              </Text>
              <Text size="md" ml="md">
                Causes:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Symptoms"
                  placeholder="Select the symptoms..."
                  data={symptoms}
                  {...form.getInputProps('relations.causes.symptoms')}
                />
              </Flex>
              <Text size="md" mt="md" ml="md">
                Treated by:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Treatments"
                  placeholder="Select the treatments..."
                  data={treatments}
                  {...form.getInputProps('relations.treated_by.treatments')}
                />
              </Flex>
              <Text size="md" mt="md" ml="md">
                Is supertype of:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Conditions"
                  placeholder="Select the conditions..."
                  data={conditions}
                  {...form.getInputProps('relations.is_supertype_of.conditions')}
                />
              </Flex>
              <Text size="md" mt="md" ml="md">
                Is subtype of:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Conditions"
                  placeholder="Select the conditions..."
                  data={conditions}
                  {...form.getInputProps('relations.is_subtype_of.conditions')}
                />
              </Flex>
            </Box>

            <Box>
              <MultiSelect
                mt="lg"
                creatable
                searchable
                className={classes.multiselect}
                label="Keywords"
                placeholder="Enter the keywords..."
                data={form.values.keywords}
                getCreateLabel={() => 'Create keyword'}
                {...form.getInputProps('keywords')}
              />
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
