export function itemsToDropdown(items: any) {
    return items.map((item: any) => ({
        label: item.name,
        value: item._id,
    }));
}
export function organismsToDropdown(items: any) {
    return items.map((item: any) => ({
        label: `${item.name} (${item._id})`,
        value: item._id,
    }));
}

export function getName(firstName: string | undefined, lastName: string | undefined) {
    if (!firstName && !lastName) {
      return 'N/A';
    } else {
      return `${firstName} ${lastName}`;
    }
  }