import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mantine/core';

import { clearToken } from 'services/token';

export default function LogoutButton() {
    const { logout } = useAuth0();

    const handleLogout = () => {
        clearToken();
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <Button h='30px' onClick={handleLogout} variant="outline">
            Log Out
        </Button>
    );
}