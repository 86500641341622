import { IUser } from 'types/user';
import { emptySplitApi } from './emptyApi';
import { AllUsers } from 'types/allUsers';

const userApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<IUser, any>({
            query: ({ id }) => ({
                url: `/api/portal/user/${id}`,
            }),
        }),
        getAllUsers: builder.query<AllUsers, void>({
            query: () => ({
                url: `/api/portal/user/all`,
            }),
        }),
        updateUser: builder.mutation({
            query: ({ fields }) => ({
                url: `/api/portal/user`,
                method: 'PATCH',
                body: fields,
            }),
        }),
        auth: builder.mutation({
            query: ({ fields }) => ({
                url: '/api/portal/user',
                method: 'POST',
                body: fields,
            }),
        }),
    }),
});

export default userApi;
