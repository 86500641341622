import React from 'react';
import { Box, Title, Text, Badge, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import { useAppSelector } from 'hooks';

export default function PaperResultsItem(item: any) {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const version = useAppSelector((state) => state.kb.version);

    function handleClick() {
        navigate(`/kb/${version}/symptoms/${item._id}`);
    }

    return (
        <Box className={classes.container} onClick={handleClick}>
            <Row>
                <Label className={classes.labelItem}>{item._id}</Label>
                <Label className={classes.createdAt}>
                    Created {moment(item.createdAt).fromNow()}
                </Label>
                <Label className={classes.editedAt}>
                    Edited {moment(item.updatedAt).fromNow()}
                </Label>
            </Row>
            <Title className={classes.title}>{item.name}</Title>
            <Row>
                {item.is_display && (
                    <Badge mr="sm" radius="xs">
                        Displayed
                    </Badge>
                )}
                <Badge mr="sm" radius="xs" className={classes.defaultBadge}>
                    {item.category}
                </Badge>
            </Row>
            <Text className={classes.description}>{item.description}</Text>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.blue[0],
        },
    },
    title: {
        marginTop: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
        fontSize: theme.fontSizes.xl,
        textTransform: 'capitalize',
    },
    description: {
        maxHeight: '200px',
        marginTop: theme.spacing.md,
        color: theme.colors.gray[6],
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    labelItem: {
        marginRight: theme.spacing.md,
    },
    createdAt: {
        marginLeft: 'auto',
    },
    editedAt: {
        marginLeft: theme.spacing.md,
    },
    defaultBadge: {
        maxWidth: '300px',
        color: theme.colors.gray[6],
        background: theme.colors.gray[1],
    },
}));
