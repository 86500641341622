import { Box, Title, Table, Text, createStyles, Group, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from 'pages/Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import Forbidden from 'pages/Forbidden';
import { useDisclosure } from '@mantine/hooks';
import sampleApi from 'services/sample';
import CreateSampleModal from './CreateSampleModal';
import { IUserSample } from 'types/sample/sampleApiTypes';
import moment from 'moment';

export default function AllSamples() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [opened, { close, open, toggle }] = useDisclosure(false);
  const { data, isLoading, error, refetch } = sampleApi.endpoints.getAllSamples.useQuery(undefined);

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  const numSamples = data.length;

  function handleClick(sample: any) {
    navigate(`/samples/${sample.id}/edit`);
  }

  function toggleCreateModal() {
    toggle();
  }

  async function createSampleCallback() {
    close();
    await refetch();
  }

  return (
    <Box className={classes.container}>
      <Row className={classes.header}>
        <Column className={classes.box}>
          <Label>Total samples under management</Label>
          <Title>{numSamples}</Title>
        </Column>
        <Column className={classes.box}>
          <Group position="center">
            <CreateSampleModal opened={opened} callback={createSampleCallback} close={close} />
            <Button onClick={toggleCreateModal}>Create Sample</Button>
          </Group>
        </Column>
      </Row>

      <Table verticalSpacing="md" striped highlightOnHover>
        <thead>
          <tr>
            <th>Barcode</th>
            <th>Sample User Id</th>
            <th>Intake Complete</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {data.map((sample: IUserSample) => (
            <tr key={sample.id} className={classes.row} onClick={() => handleClick(sample)}>
              <td>
                {sample.barcode ? (
                  <Text ml="sm">{sample.barcode}</Text>
                ) : (
                  <Text ml="sm" color="red">
                    No barcode
                  </Text>
                )}
              </td>
              <td>
                {sample.user ? (
                  <Text ml="sm">{sample.user.email}</Text>
                ) : (
                  <Text ml="sm" color="red">
                    No User
                  </Text>
                )}
              </td>
              <td>
              {
                sample.intake ? (
                  <Text ml="sm">{sample.intake?.completedAt ? `${moment(sample.intake?.completedAt).format('L')} ${moment(sample.intake?.completedAt).format('LT')}` : 'Not complete'}</Text>
                ) : (
                  <Text ml="sm" color="red">
                    No Intake
                  </Text>
                )}
       
              </td>
              <td>
                <Text size='xs' ff='monospace' ml="sm">{moment(sample.createdAt).format('L')} {moment(sample.createdAt).format('LT')}</Text>
              </td>
              <td>
                <Text size='xs' ff='monospace' ml="sm">{moment(sample.updatedAt).format('L')} {moment(sample.updatedAt).format('LT')}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    margin: theme.spacing.lg,
  },
  box: {
    width: 'fit-content',
    padding: theme.spacing.md,
    marginRight: theme.spacing.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '4px',
  },
  header: {
    marginBottom: theme.spacing.lg,
  },
  row: {
    cursor: 'pointer',
  },
}));
