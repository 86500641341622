
import { TagTypes, emptySplitApi } from './emptyApi';
import { CreateProviderRequest, Provider, ProviderSummary } from 'types/provider/providerApiTypes';

const BASE_PATH = '/api/portal/provider';

const providerApi = emptySplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getProvider: builder.query<Provider, { id: string | undefined}>({
            query: ({id}) => ({
                url: `${BASE_PATH}/${id}`,
            }),
            providesTags: (result, error, arg) => (result?.id ? [{ type: TagTypes.provider, id: result.id }] : []),
        }),
        getProviderSummary: builder.query<ProviderSummary, { id: string | undefined}>({
            query: ({id}) => ({
                url: `${BASE_PATH}/${id}/summary`,
            }),
        }),
        getAllProviders: builder.query<Provider[], undefined> ({
            query: () => ({
                url: `${BASE_PATH}`,
            }),
            providesTags: (result) => (result ? [...result.map((provider) => ({ type: TagTypes.provider, id: provider.id }))] : []),
        }),
        createProvider: builder.mutation<Provider, CreateProviderRequest>({
            query: ({ name, email }) => ({
                url: `${BASE_PATH}`,
                method: 'POST',
                body: { name, email },
            }),
            invalidatesTags: [{ type: TagTypes.provider }],
        }),
        updateProvider: builder.mutation<Provider, Partial<Provider>>({
            query: (provider) => ({
                url: `${BASE_PATH}/${provider.id}`,
                method: 'POST',
                body: provider,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
        deleteProvider: builder.mutation({
            query: ({id}) => ({
                url: `${BASE_PATH}/${id}/delete`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: TagTypes.provider }],
        }),
        updateOrderCode: builder.mutation<Provider, Partial<Provider>>({
            query: ({id, shopifyOrderCode: orderCode}) => ({
                url: `${BASE_PATH}/${id}/orderCode`,
                method: 'POST',
                body: { orderCode: orderCode },
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
        assignStaff: builder.mutation({
            query: ({id, staffId}) => ({
                url: `${BASE_PATH}/${id}/staff/add`,
                method: 'POST',
                body: {
                    staffId
                }
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
        removeStaff: builder.mutation({
            query: ({id, staffId}) => ({
                url: `${BASE_PATH}/${id}/staff/remove`,
                method: 'POST',
                body: {
                    staffId
                }
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
        assignClient: builder.mutation({
            query: ({id, clientId}) => ({
                url: `${BASE_PATH}/${id}/client/add`,
                method: 'POST',
                body: {
                    clientId
                }
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
        removeClient: builder.mutation({
            query: ({id, clientId}) => ({
                url: `${BASE_PATH}/${id}/client/remove`,
                method: 'POST',
                body: {
                    clientId
                }
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.provider, id: arg.id }]),
        }),
    }),
});

export default providerApi;
