// @ts-nocheck
import React, {
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import {
    createStyles,
    NumberInput,
    Select,
    Flex,
    Box,
    Text,
    Textarea,
    MultiSelect,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import debounce from 'debounce';

import Loader from 'components/Loader';
import kbApi from 'services/kb';
import {
    itemsToDropdown,
    organismsToDropdown
} from 'util/transforms';
import { INSIGHT_TYPES } from 'util/constants';
import { useAppSelector } from 'hooks';


export default function InsightForm(item: any) {
    const { classes } = useStyles();
    const { user } = useAuth0();
    const formRef = useRef(null);
    const { paperId } = useParams();
    const version = useAppSelector((state) => state.kb.version);
    const [organismCache, setOrganismCache] = useState(
        item.microbes
            ? organismsToDropdown([
                ...item.microbes.gain_microbes,
                ...item.microbes.loss_microbes,
                ...item.microbes.presence_microbes,
            ])
            : []
    );
    const [organismSearchGain, setOrganismSearchGain] = useState('');
    const [organismSearchLoss, setOrganismSearchLoss] = useState('');
    const [organismSearchPresence, setOrganismSearchPresence] = useState('');
    const debouncedOrganismSearchGain = useDebouncedValue(
        organismSearchGain,
        500
    );
    const debouncedOrganismSearchLoss = useDebouncedValue(
        organismSearchLoss,
        500
    );
    const debouncedOrganismSearchPresence = useDebouncedValue(
        organismSearchPresence,
        500
    );

    const form = useForm({
        initialValues: {
            status: 'needs review',
            study_size: 0,
            study_type: '',
            subject_type: [],
            test_type: '',
            sequencing_type: '',
            paper_type: '',
            evidence: '',
            notes: '',
            conditions: [],
            sensitivities: [],
            symptoms: [],
            diets: [],
            treatments: [],
            metabolisms: [],
            microbes: {
                gain_microbes: [],
                loss_microbes: [],
                presence_microbes: [],
            },
        },
        // validate: yupResolver(schema),
    });

    useEffect(() => {
        if (item._id) {
            form.setValues({
                status: item.status,
                study_size: item.study_size,
                study_type: item.study_type,
                subject_type: item.subject_type,
                test_type: item.test_type,
                sequencing_type: item.sequencing_type,
                paper_type: item.paper_type,
                evidence: item.evidence,
                notes: item.notes,
                conditions: item.diseases
                    .filter(
                        (disease: any) => disease.collection === 'conditions'
                    )
                    .map((disease: any) => disease._id),
                sensitivities: item.diseases
                    .filter(
                        (disease: any) => disease.collection === 'sensitivities'
                    )
                    .map((disease: any) => disease._id),
                symptoms: item.diseases
                    .filter((disease: any) => disease.collection === 'symptoms')
                    .map((disease: any) => disease._id),
                diets: item.actions
                    .filter((action: any) => action.collection === 'diets')
                    .map((action: any) => `${action.type}:${action._id}`),
                treatments: item.actions
                    .filter((action: any) => action.collection === 'treatments')
                    .map((action: any) => `${action.type}:${action._id}`),
                metabolisms:
                    item.metabolisms &&
                    item.metabolisms.map((metabolism: any) => metabolism._id),
                microbes: {
                    gain_microbes: item.microbes.gain_microbes.map(
                        (organism: any) => organism._id
                    ),
                    loss_microbes: item.microbes.loss_microbes.map(
                        (organism: any) => organism._id
                    ),
                    presence_microbes: item.microbes.presence_microbes.map(
                        (organism: any) => organism._id
                    ),
                },
            });
        }
    }, []);

    useEffect(() => {
        if (item.forwardRef) {
            item.forwardRef(formRef);
        }
    }, [formRef]);

    useImperativeHandle(item.forwardRef, () => ({
        triggerSyntheticSubmit: () => {
            const submitEvent = new Event('submit', {
                bubbles: true,
                cancelable: true,
            });
            formRef.current.dispatchEvent(submitEvent);
        },
    }));

    const { data: organismData, isLoading: isOrganismsLoading } =
        kbApi.endpoints.getOrganisms.useQuery(
            {
                name:
                    debouncedOrganismSearchGain ||
                    debouncedOrganismSearchLoss ||
                    debouncedOrganismSearchPresence,
            },
            { refetchOnMountOrArgChange: true }
        );
    const { data: conditionData, isLoading: isConditionsLoading } =
        kbApi.endpoints.getConditions.useQuery({ version });
    const { data: symptomData, isLoading: isSymptomsLoading } =
        kbApi.endpoints.getSymptoms.useQuery({ version });
    const { data: dietData, isLoading: isDietsLoading } =
        kbApi.endpoints.getDiets.useQuery({ version });
    const { data: treatmentData, isLoading: isTreatmentsLoading } =
        kbApi.endpoints.getTreatments.useQuery({ version });
    const { data: metabolismData, isLoading: isMetabolismsLoading } =
        kbApi.endpoints.getMetabolisms.useQuery({ version });
    const { data: sensitivityData, isLoading: isSensitivitiesLoading } =
        kbApi.endpoints.getSensitivities.useQuery({ version });
    const [updateInsight] = kbApi.endpoints.updateInsight.useMutation();
    const [createInsight] = kbApi.endpoints.createInsight.useMutation();

    useEffect(() => {
        if (organismData) {
            const uniqueOrganisms = new Set();
            let tempOrganisms = [...organismCache];
            tempOrganisms.push(...organismsToDropdown(organismData));
            tempOrganisms = tempOrganisms.filter((organism: any) => {
                if (!uniqueOrganisms.has(organism.value)) {
                    uniqueOrganisms.add(organism.value);
                    return true;
                }
                return false;
            });
            setOrganismCache(tempOrganisms);
        }
    }, [organismData]);

    const loading =
        isOrganismsLoading ||
        isConditionsLoading ||
        isSymptomsLoading ||
        isTreatmentsLoading ||
        isMetabolismsLoading ||
        isSensitivitiesLoading ||
        isDietsLoading;
    if (loading) {
        return <Loader />;
    }

    function useDebouncedValue(value, delay) {
        const [debouncedValue, setDebouncedValue] = useState(value);

        const debounced = useCallback(
            debounce((nextValue) => setDebouncedValue(nextValue), delay),
            [delay]
        );

        React.useEffect(() => {
            debounced(value);
            return debounced.cancel;
        }, [value, debounced]);

        return debouncedValue;
    }

    async function handleSubmit(values: any, event: any) {
        event.preventDefault();

        if (item._id) {
            await updateInsight({
                id: item._id,
                version: item.version,
                kbVersion: version,
                editor: user?.nickname,
                ...values,
            });
        } else {
            await createInsight({
                paper_id: paperId,
                version: 1,
                kbVersion: version,
                editor: user?.nickname,
                ...values,
            });
        }

        await item.refetchPaper();
    }

    function addTypes(items: any) {
        const itemsWithTypes = [];
        items.map((item) => {
            itemsWithTypes.push({
                label: `${INSIGHT_TYPES.ACTION_TYPES[0]} : ${item.label}`,
                value: `${INSIGHT_TYPES.ACTION_TYPES[0]}:${item.value}`,
            });
            itemsWithTypes.push({
                label: `${INSIGHT_TYPES.ACTION_TYPES[1]} : ${item.label}`,
                value: `${INSIGHT_TYPES.ACTION_TYPES[1]}:${item.value}`,
            });
            itemsWithTypes.push({
                label: `${INSIGHT_TYPES.ACTION_TYPES[2]} : ${item.label}`,
                value: `${INSIGHT_TYPES.ACTION_TYPES[2]}:${item.value}`,
            });
            itemsWithTypes.push({
                label: `${INSIGHT_TYPES.ACTION_TYPES[3]} : ${item.label}`,
                value: `${INSIGHT_TYPES.ACTION_TYPES[3]}:${item.value}`,
            });
        });
        return itemsWithTypes;
    }

    const conditions = itemsToDropdown(conditionData);
    const symptoms = itemsToDropdown(symptomData);
    const diets = addTypes(itemsToDropdown(dietData));
    const treatments = addTypes(itemsToDropdown(treatmentData));
    const metabolisms = itemsToDropdown(metabolismData);
    const sensitivities = itemsToDropdown(sensitivityData);

    return (
        <Flex>
            <form
                ref={formRef}
                className={classes.form}
                onSubmit={form.onSubmit(handleSubmit)}
            >
                <Box p="lg">
                    <Box className={classes.section}>
                        <Text size="lg" mb="md">
                            Details
                        </Text>
                        <Flex>
                            <Select
                                searchable
                                pr="lg"
                                className={classes.fieldCol}
                                label="Status"
                                placeholder="Select the study type..."
                                data={INSIGHT_TYPES.STATUSES}
                                {...form.getInputProps('status')}
                            />
                        </Flex>
                        <Flex mt="lg">
                            <NumberInput
                                className={classes.fieldCol}
                                label="Study Size"
                                placeholder="Enter the study size..."
                                min={0}
                                {...form.getInputProps('study_size')}
                            />
                            <Select
                                searchable
                                ml="xl"
                                className={classes.fieldCol}
                                label="Study Type"
                                placeholder="Select the study type..."
                                data={INSIGHT_TYPES.STUDY_TYPES}
                                {...form.getInputProps('study_type')}
                            />
                        </Flex>
                        <Flex mt="lg">
                            <MultiSelect
                                className={classes.fieldCol}
                                label="Subject Type"
                                placeholder="Select the subject type..."
                                data={INSIGHT_TYPES.SUBJECT_TYPES}
                                {...form.getInputProps('subject_type')}
                            />
                            <Select
                                searchable
                                ml="xl"
                                className={classes.fieldCol}
                                label="Test Type"
                                placeholder="Select the test type..."
                                data={INSIGHT_TYPES.TEST_TYPES}
                                {...form.getInputProps('test_type')}
                            />
                        </Flex>
                        <Flex mt="lg">
                            <Select
                                pr="lg"
                                searchable
                                className={classes.fieldCol}
                                label="Sequencing Type"
                                placeholder="Select the sequencing type..."
                                data={INSIGHT_TYPES.SEQUENCING_TYPES}
                                {...form.getInputProps('sequencing_type')}
                            />
                            {/* <Select
                                searchable
                                ml="xl"
                                className={classes.fieldCol}
                                label="Paper Type"
                                placeholder="Select the paper type..."
                                data={INSIGHT_TYPES.PAPER_TYPES}
                                {...form.getInputProps('paper_type')}
                            /> */}
                        </Flex>
                    </Box>

                    <Box className={classes.section}>
                        <Text size="lg" mb="md">
                            Diseases
                        </Text>
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Conditions"
                            placeholder="Select the conditions..."
                            data={conditions}
                            {...form.getInputProps('conditions')}
                        />
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Sensitivities"
                            placeholder="Select the sensitivities..."
                            data={sensitivities}
                            {...form.getInputProps('sensitivities')}
                        />
                        <MultiSelect
                            searchable
                            className={classes.multiselect}
                            label="Symptoms"
                            placeholder="Select the symptoms..."
                            data={symptoms}
                            {...form.getInputProps('symptoms')}
                        />
                    </Box>

                    <Box className={classes.section}>
                        <Text size="lg" mb="md">
                            Actions
                        </Text>
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Diets"
                            placeholder="Select diets..."
                            data={diets}
                            {...form.getInputProps('diets')}
                        />
                        <MultiSelect
                            searchable
                            className={classes.multiselect}
                            label="Treatments"
                            placeholder="Select treatments..."
                            data={treatments}
                            {...form.getInputProps('treatments')}
                        />
                    </Box>

                    <Box className={classes.section}>
                        <Text size="lg" mb="md">
                            Metabolisms
                        </Text>
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Metabolisms"
                            placeholder="Select metabolisms..."
                            data={metabolisms}
                            {...form.getInputProps('metabolisms')}
                        />
                    </Box>

                    <Box>
                        <Text size="lg" mb="md">
                            Organisms
                        </Text>
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Gain"
                            placeholder="Select organisms gained..."
                            data={organismCache}
                            searchValue={organismSearchGain}
                            onSearchChange={(value) =>
                                setOrganismSearchGain(value)
                            }
                            limit={20}
                            {...form.getInputProps('microbes.gain_microbes')}
                        />
                        <MultiSelect
                            searchable
                            mb="lg"
                            className={classes.multiselect}
                            label="Loss"
                            placeholder="Select organisms lost..."
                            data={organismCache}
                            searchValue={organismSearchLoss}
                            onSearchChange={(value) =>
                                setOrganismSearchLoss(value)
                            }
                            limit={20}
                            {...form.getInputProps('microbes.loss_microbes')}
                        />
                        <MultiSelect
                            searchable
                            className={classes.multiselect}
                            label="Presence"
                            placeholder="Select organisms..."
                            data={organismCache}
                            searchValue={organismSearchPresence}
                            onSearchChange={(value) =>
                                setOrganismSearchPresence(value)
                            }
                            limit={20}
                            {...form.getInputProps(
                                'microbes.presence_microbes'
                            )}
                        />
                    </Box>

                    <Box mt="xl">
                        <Text size="lg" mb="md">
                            Comments
                        </Text>
                        <Textarea
                            className={classes.description}
                            label="Notes"
                            minRows={6}
                            placeholder="Enter notes..."
                            {...form.getInputProps('notes')}
                        />
                        <Textarea
                            mt="lg"
                            className={classes.description}
                            label="Evidence"
                            placeholder="Enter evidence..."
                            minRows={6}
                            {...form.getInputProps('evidence')}
                        />
                    </Box>
                </Box>
            </form>
        </Flex>
    );
}

const useStyles = createStyles((theme) => ({
    form: {
        width: '100%',
    },
    section: {
        paddingBottom: theme.spacing.lg,
        marginBottom: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
    },
    fieldCol: {
        width: '50%',
    },
    multiselect: {
        width: '100%',
    },
}));
