import { useEffect, useRef, useState } from 'react';
import {
  createStyles,
  TextInput,
  Textarea,
  Badge,
  Grid,
  Select,
  Flex,
  Box,
  Text,
  MultiSelect,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Loading from 'pages/Loading';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import { useAppSelector } from 'hooks';
import { cloneDeep } from 'lodash';
import { DescriptionList } from 'types/kb/prerelease';
import MetaHeader from 'components/SharedForms/MetaHeader';
import { GeneralDescriptionsForm } from 'components/SharedForms/GeneralDescriptionsForm';
import { BulletPointDescriptionsForm } from 'components/SharedForms/BulletPointDescriptionsForm';
import Label from 'components/Label';

const CATEGORIES = [
  'Neuropsychiatric Symptom',
  'Gastrointestinal Symptom',
  'Mental Health Symptom',
  'Neurological Symptom',
  'Metabolic Symptom',
  'Musculoskeletal Symptom',
  'Cardiovascular Symptom',
  'Respiratory Symptom',
  'General Symptom',
  'Dermatological Symptom',
  'Ocular Symptom',
  'Urological Symptom',
  'Laryngological Symptom',
];

export default function SymptomForm(item: any) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const formRef = useRef(null);
  const version = useAppSelector((state) => state.kb.version);
  const { symptomId } = useParams();
  const [isCreating, setIsCreating] = useState(symptomId === 'new');

  const initialValues = {
    name: '',
    abbreviation: '',
    preferred_name: '',
    mesh_id: '',
    keywords: [],
    description: '',
    one_line_description: '',
    bullet_point_description: [] as DescriptionList[],
    is_display: false,
should_recommend: false,
    category: '',
    relations: {
      caused_by: {
        conditions: [],
        metabolisms: [],
        organisms: [],
      },
      is_subtype_of: {
        symptoms: [],
      },
      is_supertype_of: {
        symptoms: [],
      },
    },
  };

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (!item._id) {
      form.setValues(initialValues);
    } else {
      form.setValues({
        name: item.name,
        abbreviation: item.abbreviation,
        keywords: item.keywords,
        preferred_name: item.preferred_name,
        mesh_id: item.mesh_id,
        description: item.description,
        one_line_description: item.one_line_description,
        bullet_point_description: cloneDeep(item.bullet_point_description) ?? [] as DescriptionList[],
        is_display: item.is_display,
should_recommend: item.should_recommend,
        category: item.category,
        relations: {
          caused_by: {
            conditions: item.relations.caused_by.conditions,
            metabolisms: item.relations.caused_by.metabolisms,
            organisms: item.relations.caused_by.organisms,
          },
          is_subtype_of: {
            symptoms: item.relations.is_subtype_of.symptoms,
          },
          is_supertype_of: {
            symptoms: item.relations.is_supertype_of.symptoms,
          },
        },
      });
    }
  }, []);

  const [updateSymptom, { isLoading }] = kbApi.endpoints.updateSymptom.useMutation();
  const { data: symptomData, isLoading: isSymptomsLoading } = kbApi.endpoints.getSymptoms.useQuery({ version });
  const { data: conditionData, isLoading: isConditionsLoading } = kbApi.endpoints.getConditions.useQuery({ version });
  const { data: metabolismData, isLoading: isMetabolismsLoading } = kbApi.endpoints.getMetabolisms.useQuery({
    version,
  });
  const { data: organismData, isLoading: isOrganismsLoading } = kbApi.endpoints.getOrganisms.useQuery({ version });

  const loading = isSymptomsLoading || isConditionsLoading || isMetabolismsLoading || isOrganismsLoading;
  if (loading) {
    return <Loading />;
  }

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateSymptom({
      id: item._id || 'new',
      version: version,
      ...values,
    });
    await item.refetch();

    if (isCreating) {
      navigate(`/kb/${version}/lifestyles`);
    }
  }

  function handleCreateKeyword(synonym: string) {
    form.insertListItem('keywords', synonym);
  }

  const symptoms = itemsToDropdown(symptomData);
  const conditions = itemsToDropdown(conditionData);
  const metabolisms = itemsToDropdown(metabolismData);
  const organisms = itemsToDropdown(organismData);

  return (
    <form ref={formRef} className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Grid.Col span={2} order={2}>
          <MetaHeader
            collectionName="Symptom"
            createdAt={item.createdAt}
            updatedAt={item.updatedAt}
            //@ts-ignore
            form={form}
            saveCallback={form.onSubmit(handleSubmit)}
          />
        </Grid.Col>
        <Grid.Col span={10} order={1}>
        <Box p="lg" className={classes.container} miw={{ base:'550px', md: '800px'}}>
        <Box className={classes.section}>
          <Flex justify="space-between" align="center">
            <Text size="lg" mb="md">
              Details
            </Text>
            <Badge radius="sm" variant="filled" size="lg">
              {version}
            </Badge>
          </Flex>
          <Flex>
            <TextInput
              className={classes.fieldCol}
              label="Name"
              placeholder="Enter the name..."
              {...form.getInputProps('name')}
            />
            <TextInput ml="xl" className={classes.fieldCol} label="ID" disabled value={item._id} />
          </Flex>
          <Flex mt="lg">
            <Select
              className={classes.fieldCol}
              label="Category"
              placeholder="Enter the category..."
              data={CATEGORIES}
              {...form.getInputProps('category')}
            />
            <TextInput
              ml="xl"
              className={classes.fieldCol}
              label="Abbreviation"
              placeholder="Enter the abbreviation..."
              {...form.getInputProps('abbreviation')}
            />
          </Flex>
          <Flex mt="md">
            <TextInput
              className={classes.fieldCol}
              label="Preferred name"
              placeholder="Enter the preferred name..."
              {...form.getInputProps('preferred_name')}
            />
            <TextInput ml="xl" className={classes.fieldCol} label="MeSH ID" {...form.getInputProps('mesh_id')} />
          </Flex>
        </Box>

        <Box className={classes.section}>
              <Flex direction="column" gap="lg">
                <GeneralDescriptionsForm
                  //@ts-ignore
                  form={form}
                />

                <Label>Bulleted Description List</Label>
                <BulletPointDescriptionsForm
                  //@ts-ignore
                  form={form}
                />
              </Flex>
            </Box>

        <Box className={classes.section}>
          <Text size="lg" mb="md">
            Relationships
          </Text>
          <Text size="md" ml="md">
            Caused by:
          </Text>
          <Flex>
            <MultiSelect
              searchable
              ml="xl"
              className={classes.multiselect}
              label="Conditions"
              placeholder="Select the conditions..."
              data={conditions}
              {...form.getInputProps('relations.caused_by.conditions')}
            />
          </Flex>
          <Flex>
            <MultiSelect
              searchable
              ml="xl"
              className={classes.multiselect}
              label="Metabolisms"
              placeholder="Select the metabolisms..."
              data={metabolisms}
              {...form.getInputProps('relations.caused_by.metabolisms')}
            />
          </Flex>
          <Flex>
            <MultiSelect
              searchable
              ml="xl"
              className={classes.multiselect}
              label="Organisms"
              placeholder="Select the organisms..."
              data={organisms}
              {...form.getInputProps('relations.caused_by.organisms')}
            />
          </Flex>
          <Text size="md" mt="md" ml="md">
            Is supertype of:
          </Text>
          <Flex>
            <MultiSelect
              searchable
              ml="xl"
              className={classes.multiselect}
              label="Symptoms"
              placeholder="Select the symptoms..."
              data={symptoms}
              {...form.getInputProps('relations.is_supertype_of.symptoms')}
            />
          </Flex>
          <Text size="md" mt="md" ml="md">
            Is subtype of:
          </Text>
          <Flex>
            <MultiSelect
              searchable
              ml="xl"
              className={classes.multiselect}
              label="Symptoms"
              placeholder="Select the symptoms..."
              data={symptoms}
              {...form.getInputProps('relations.is_subtype_of.symptoms')}
            />
          </Flex>
        </Box>

        <Box>
          <Text size="lg" mb="md">
            Associations
          </Text>
          <MultiSelect
            mt="lg"
            searchable
            creatable
            className={classes.multiselect}
            label="Keywords"
            placeholder="Select the keywords..."
            data={form.values.keywords}
            getCreateLabel={() => 'Create synonym'}
            {...form.getInputProps('keywords')}
          />
        </Box>
      </Box>
      </Grid.Col>
      </Grid>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
