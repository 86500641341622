import { MantineProvider, Global } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Notifications } from '@mantine/notifications';
import Auth0Provider from 'contexts/auth0';

import Routes from 'Routes';
import theme from 'styles/theme';
import global from 'styles/global';
import { store } from 'store';

const App = () => {
    return (
        <Provider store={store}>
            <MantineProvider theme={theme}>
                <Global styles={global} />
                <Notifications position="top-right" />
                <BrowserRouter>
                    <Auth0Provider>
                        <Routes />
                    </Auth0Provider>
                </BrowserRouter>
            </MantineProvider>
        </Provider>
    );
};

export default App;
