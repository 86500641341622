import { Box, Title, Table, createStyles, Badge, Flex, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import userApi from 'services/user';
import Forbidden from './Forbidden';
import DownloadEmailListButton from 'components/DownloadEmailListButton';
import DownloadDemographicsButton from 'components/DownloadDemographicsButton';
import DownloadSampleDatesButton from 'components/DownloadSampleDatesButton';
import { UserSample, UserSummary } from 'types/allUsers';
import { Link } from 'react-router-dom';
import { getColorBySampleStatus } from 'util/sampleHelpers';

export default function Users() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { data, isLoading, error } = userApi.endpoints.getAllUsers.useQuery();

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  const numUsers = data?.users.length;
  const numBarcodes = data?.users.reduce((total: number, user: UserSummary) => {
    total += user.kits.length;
    return total;
  }, 0);

  function handleClick(user: any) {
    navigate(`/users/${user.id}`);
  }

  return (
    <Box className={classes.container}>
      <Row className={classes.header}>
        <Column className={classes.box}>
          <Label>Total users</Label>
          <Title>{numUsers}</Title>
        </Column>
        <Column className={classes.box}>
          <Label>Kits Registered</Label>
          <Title>{numBarcodes}</Title>
        </Column>
        <Column className={classes.box}>
          <Label>Email List</Label>
          <DownloadEmailListButton />
        </Column>
        <Column className={classes.box}>
          <Label>Demographics List</Label>
          <DownloadDemographicsButton />
        </Column>
        <Column className={classes.box}>
          <Label>Sample Dates List</Label>
          <DownloadSampleDatesButton />
        </Column>
      </Row>

      <Table verticalSpacing="md" striped highlightOnHover withBorder withColumnBorders>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Barcodes</th>
          </tr>
        </thead>
        <tbody>
          {data && [...data.users].map((user: UserSummary) => (
            <tr key={user.id} className={classes.row}>
              <td onClick={() => handleClick(user)}>{user.id.toString()}</td>
              <td onClick={() => handleClick(user)}>
                {user.firstName} {user.lastName}
              </td>
              <td onClick={() => handleClick(user)}>{user.email}</td>
              <td>
                <Flex gap='xs' wrap='wrap' >
                  {user.kits.map((kit: UserSample, i: number) => {
                    return (
                      <Tooltip key={`kit-badge-${i}`} label={kit.sampleStatus}>
                        <Link to={`/samples/${kit.id}`}>
                          <Badge    
                            color={getColorBySampleStatus(kit.sampleStatus)}
                            size="md"
                            variant="dot"
                            radius="xs"
                          >
                            {kit.barcode}
                          </Badge>
                        </Link>
                      </Tooltip>
                    );
                  })}
                </Flex>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    margin: theme.spacing.lg,
  },
  box: {
    width: 'fit-content',
    padding: theme.spacing.md,
    marginRight: theme.spacing.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '4px',
  },
  header: {
    marginBottom: theme.spacing.lg,
  },
  row: {
    cursor: 'pointer',
  },
}));
