import {
  Box,
  Text,
  createStyles,
  Flex,
  Grid,
  TextInput,
  Button,
  Title,
  Select,
  SelectItem,
  Switch,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import MetaHeader from 'components/SharedForms/MetaHeader';
import UserSearchPickerModal from 'components/UserSearchPickerModal';
import { useDisclosure } from '@mantine/hooks';
import { IPickerUser } from 'services/userV2';
import { IPickerSampleIntake, IUserSample } from 'types/sample/sampleApiTypes';
import sampleApi from 'services/sample';
import { notifications } from '@mantine/notifications';
import UserTable from 'components/UserTable';
import moment from 'moment';

interface SampleFormProps {
  sample: IUserSample;
}

export default function SampleForm({ sample }: SampleFormProps) {
  const { classes } = useStyles();
  const formRef = useRef(null);
  const [sampleUserModalOpen, { close: closeSampleUser, open: openSampleUser }] = useDisclosure(false);
  const [releaseChecked, setReleaseChecked] = useState(sample.releasedToClient);

  const mapSampleToForm = (sample: IUserSample) => ({
    id: sample.id,
    user: sample.user,
    intake: {
      id: sample.intake?.id,
      createdAt: sample.intake?.createdAt,
      updatedAt: sample.intake?.updatedAt,
    } as IPickerSampleIntake,
    barcode: sample.barcode,
    releasedToClient: sample.releasedToClient,
    createdAt: sample.createdAt,
    updatedAt: sample.updatedAt,
  });

  const form = useForm({ initialValues: mapSampleToForm(sample) });
  useEffect(() => {
    form.setValues(mapSampleToForm(sample));
  }, [sample]);

  const [updateSample, { isLoading: isUpdateSampleLoading }] = sampleApi.endpoints.updateSample.useMutation();
  const [assignUser, { isLoading: isAssignUserLoading }] = sampleApi.endpoints.assignUser.useMutation();
  const [removeUser, { isLoading: isRemoveUserLoading }] = sampleApi.endpoints.removeUser.useMutation();
  const [assignIntake, { isLoading: isAssignIntakeLoading }] = sampleApi.endpoints.assignIntake.useMutation();
  const [fetchUserIntakes, { data: intakes, isLoading, isFetching }] =
    sampleApi.endpoints.getUserIntakes.useLazyQuery();

  const loading =
    isUpdateSampleLoading ||
    isAssignUserLoading ||
    isRemoveUserLoading ||
    isAssignIntakeLoading ||
    isLoading ||
    isFetching;

  useEffect(() => {
    async function fetchData() {
      if (sample.user?.id) await fetchUserIntakes(sample.user?.id);
    }

    fetchData();
  }, [sample, fetchUserIntakes]);

  function mapIntakesToSelect(): SelectItem[] {
    if (intakes) {
      return intakes.map(
        (intake): SelectItem => ({
          key: intake.id,
          value: intake.id.toString(),
          label: `${intake.id} - ${moment(intake.createdAt).format('LL')}`,
        })
      );
    } else {
      return [];
    }
  }

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateSample({
      id: sample.id,
      ...values,
    });
  }

  async function assignUserCallback(user: IPickerUser) {
    if (user) {
      const result = await assignUser({ sampleId: sample.id, userId: user.id });

      if ('error' in result) {
        notifications.show({
          title: 'Error',
          message: `Failed to assign user to sample`,
          color: 'red',
        });
      } else {
        notifications.show({
          title: 'Success',
          message: `Added ${user.id} to sample ${sample.barcode}`,
          color: 'green',
        });
      }
    }
  }

  async function clearSampleUserCallback(user?: IPickerUser) {
    if (user) {
      const result = await removeUser({ sampleId: sample.id, userId: user.id });

      if ('error' in result) {
        notifications.show({
          title: 'Error',
          message: `Failed to remove user from sample`,
          color: 'red',
        });
      } else {
        notifications.show({
          title: 'Success',
          message: `Removed ${user.id} from sample ${sample.barcode}`,
          color: 'green',
        });
      }
    }
  }

  async function editSampleIntakeCallback(intakeId?: string) {
    if (intakeId) {
      const result = await assignIntake({ sampleId: sample.id, intakeId: parseInt(intakeId) });

      if ('error' in result) {
        notifications.show({
          title: 'Error',
          message: `Failed to assign intake to sample`,
          color: 'red',
        });
      } else {
        notifications.show({
          title: 'Success',
          message: `Assigned intake ${intakeId} to sample ${sample.barcode}`,
          color: 'green',
        });
      }
    }
  }

  return (
    <form ref={formRef} onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Grid.Col span={2} order={2}>
          <MetaHeader
            isDisplay={false}
            collectionName="Sample"
            createdAt={sample.createdAt?.toString() || ''}
            updatedAt={sample.updatedAt?.toString() || ''}
            //@ts-ignore
            form={form}
            saveCallback={form.onSubmit(handleSubmit)}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col span={10} order={1}>
          <Box p="lg" className={classes.container} miw={{ base: '550px', md: '800px' }}>
            <Box className={classes.section}>
              <Flex justify="space-between" align="center">
                <Text weight="bold" mb="md">
                  {form.values.barcode}
                </Text>
              </Flex>
              <Flex gap="xl" justify="space-between" align="center">
                <TextInput className={classes.fieldCol} label="ID" disabled value={sample.id} />
              </Flex>
            </Box>

            <Box mt="xl" className={classes.section}>
              <Flex mb="md">
                <Title order={3} mr="lg">
                  Sample User
                </Title>
                <Button onClick={openSampleUser} variant="outline" color="blue">
                  Edit Sample User
                </Button>
              </Flex>
              <Flex>
                <UserSearchPickerModal
                  limit={1}
                  defaultValues={form.values.user ? [form.values.user] : []}
                  opened={sampleUserModalOpen}
                  close={closeSampleUser}
                  addUserCallback={assignUserCallback}
                  removeUserCallback={clearSampleUserCallback}
                />
                {form.values.user ? (
                  <Flex direction="column" mr="sm">
                    <UserTable users={form.values.user ? [form.values.user] : []} />
                  </Flex>
                ) : (
                  <Text color="red" weight="bold" mr="sm">
                    Unassigned
                  </Text>
                )}
              </Flex>
            </Box>

            {form.values.user?.id && (
              <>
                <Box mt="xl" className={classes.section}>
                  <Flex mb="md">
                    <Title order={3} mr="lg">
                      Intake
                    </Title>
                  </Flex>
                  <Flex>
                    <Select
                      w="100%"
                      defaultValue={form.values.intake?.id?.toString()}
                      data={mapIntakesToSelect()}
                      onChange={(value) => editSampleIntakeCallback(value || undefined)}
                    ></Select>
                  </Flex>
                </Box>
                <Box mt="xl" className={classes.section}>
                  <Flex mb="md">
                    <Title order={3} mr="lg">
                      Report Released
                    </Title>
                  </Flex>
                  <Flex>
                    <Switch
                      checked={releaseChecked}
                      onChange={async (e) => {
                        setReleaseChecked(e.currentTarget.checked);
                        await updateSample({
                          id: sample.id,
                          releasedToClient: e.currentTarget.checked,
                        });
                      }}
                      size="lg"
                      onLabel="YES"
                      offLabel="NO"
                    />
                  </Flex>
                </Box>
              </>
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
