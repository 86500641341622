import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
    user: any;
}

const initialState: UserState = {
    user: undefined,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export default userSlice;
