import providerApi from 'services/provider';
import { useForm, yupResolver } from '@mantine/form';
import { useRef } from 'react';
import { Button, Flex, Modal, TextInput, createStyles } from '@mantine/core';
import * as Yup from 'yup';
import { notifications } from '@mantine/notifications';


interface CreateProviderModalProps {
  opened: boolean;
  close: () => void;
  callback: () => void;
}

const schema = Yup.object().shape({
  name: Yup.string().required(
    "Name is required"
  ),
  email: Yup.string().email().required(
    "Email is required"
  ),
});

export default function CreateProviderModal({ opened, callback, close }: CreateProviderModalProps) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
    },
    validate: yupResolver(schema),
  });

  const [createProvider, error] = providerApi.endpoints.createProvider.useMutation();

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await createProvider({
      email: values.email,
      name: values.name,
    });

    if (error && 'data' in error) {
      notifications.show({
        title: 'Error',
        message: 'There was an error creating the provider',
        color: 'red',
      });
    } else {
      notifications.show({
        title: 'Success',
        message: 'Provider created',
        color: 'green',
      });
    }

    await callback();
  }

  return (
    <Modal opened={opened} onClose={close} title="New Provider" centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>  
          <Flex justify='center' gap='lg'>
                <TextInput
                  className={classes.fieldCol}
                  label="Name"
                  placeholder="Enter the name..."
                  {...form.getInputProps('name')}
                />
                <TextInput
                  className={classes.fieldCol}
                  label="Contact Email"
                  placeholder="Enter the contact email..."
                  {...form.getInputProps('email')}
                />
            </Flex>
            <Button mt='lg' variant='light' fullWidth type='submit'>
              Create Provider
            </Button>
        </form>
      </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  fieldCol: {
    width: '75%',
  },
}));