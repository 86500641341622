import React from 'react';
import { Box, Flex, Paper, Group, Title, Accordion, createStyles, Badge, SimpleGrid } from '@mantine/core';

import { Finding } from 'types/kb/prerelease';
import Label from './Label';
import Markdown from 'react-markdown';

interface Props {
    findings: Finding[]
}

export default function KBAccordionTable({ findings }: Props) {
    const { classes, cx } = useStyles();

    function sortAlphabetically(a: Finding, b: Finding): number {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
            return -1;
        if (nameA > nameB)
            return 1;
        return 0; //default return value (no sorting)           
    }

    let sortedFindings = [...findings];
    const findingsTable = sortedFindings.sort(sortAlphabetically).map((finding, i) => (
        <Accordion.Item key={i} value={finding.name}>
            <Accordion.Control pl="sm">
                <span className={classes.accRow}>
                    {finding.name}
                </span>
            </Accordion.Control>
            <Accordion.Panel>
                <Paper m="md">
                    <SimpleGrid spacing="sm" mb="md">
                        <Label>Category</Label>
                        <Flex align="center" wrap='wrap'>
                            <Badge>{finding.category ?? "N/A"}</Badge>
                        </Flex>
                    </SimpleGrid>
                    <SimpleGrid spacing="sm" mb="md">
                        <Label>Keywords</Label>
                        <Flex align="center" wrap='wrap'>
                            {finding.keywords.map((keyword: string, i: number) =>
                                <Badge key={i} m="xs"><Markdown>{keyword}</Markdown></Badge>
                            )}
                        </Flex>
                    </SimpleGrid>
                    <SimpleGrid spacing="sm">
                        <Label>Description</Label>
                        {finding.description ?? "N/A"}
                    </SimpleGrid>
                </Paper>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Box className={classes.container}>
            <Accordion
                variant="separated"
                radius="xs"
                classNames={{ item: classes.accItem, chevron: classes.accChevron }}
                styles={{
                    label: {
                        // styles added to all items
                        paddingTop: '8px',
                        paddingBottom: '8px'
                    }
                }}
            >
                {findingsTable}
            </Accordion>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        margin: theme.spacing.lg,
    },
    accRow: {
        fontWeight: 500,
    },
    accItem: {
        borderBottom: `1px solid ${theme.colors.gray[5]}`,
        padding: '0 5px',
        '& + &': { marginTop: '12px' },
    },
    accChevron: {
        '& + .mantine-Accordion-label': {
            overflow: 'initial', // for tooltip
        },
    },
}));
