import React from 'react';
import {
    Box,
    TextInput,
    Select,
    MultiSelect,
    Checkbox,
    Button,
    createStyles,
} from '@mantine/core';
import { useDispatch } from 'react-redux';

import Loading from 'pages/Loading';
import Label from 'components/Label';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import kbSlice from 'slices/kb';
import { useAppSelector } from 'hooks';

export default function DietFilter() {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const filters = useAppSelector((state) => state.kb.filters.diets);

    const handleTextFilterChange = (filter: string) => (event: any) => {
        dispatch(
            kbSlice.actions.updateDietFilter({
                filter,
                value: event.target.value,
            })
        );
    };
    const handleSelectFilterChange = (filter: string) => (data: any) => {
        dispatch(
            kbSlice.actions.updateDietFilter({
                filter,
                value: data,
            })
        );
    };
    const handleCheckboxFilterChange = (filter: string) => (event: any) => {
        dispatch(
            kbSlice.actions.updateDietFilter({
                filter,
                value: event.currentTarget.checked,
            })
        );
    };

    function handleClearClick() {
        dispatch(kbSlice.actions.clearPaperFilters());
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.card}>
                <Label>Options</Label>
                <Checkbox
                    mt="xs"
                    label="Display"
                    size="sm"
                    checked={filters.is_display}
                    onChange={handleCheckboxFilterChange('is_display')}
                />
                <Button
                    fullWidth
                    mt="lg"
                    variant="light"
                    onClick={handleClearClick}
                >
                    Clear Filters
                </Button>
            </Box>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '400px',
        maxWidth: '400px',
        marginRight: theme.spacing.lg,
        marginBottom: theme.spacing.xl,
    },
    card: {
        padding: theme.spacing.lg,
        marginBottom: theme.spacing.lg,
        background: 'white',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
    },
}));
