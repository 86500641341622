import React from 'react';
import { Box, Title, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import Body from 'components/Body';

export default function Dev() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Box>
            <Body>
                <Outlet/>
            </Body>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({}));
