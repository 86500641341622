import { useEffect, useRef, useState } from 'react';
import {
  createStyles,
  TextInput,
  Badge,
  Grid,
  Select,
  Flex,
  Box,
  Text,
  MultiSelect,
  SelectItem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Label from 'components/Label';
import Loading from 'pages/Loading';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import { useAppSelector } from 'hooks';
import { cloneDeep } from 'lodash';
import { DescriptionList } from 'types/kb/prerelease';
import MetaHeader from 'components/SharedForms/MetaHeader';
import { GeneralDescriptionsForm } from 'components/SharedForms/GeneralDescriptionsForm';
import {
  BulletPointDescriptionsForm,
} from 'components/SharedForms/BulletPointDescriptionsForm';

const CATEGORIES: readonly (string | SelectItem)[] = [];

export default function MetabolismForm(item: any) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const formRef = useRef(null);
  const version = useAppSelector((state) => state.kb.version);
  const { metabolismId } = useParams();
  const [isCreating, setIsCreating] = useState(metabolismId === 'new');

  const initialValues = {
    name: '',
    preferred_name: '',
    abbreviation: '',
    keywords: [],
    symptoms: [],
    description: '',
    one_line_description: '',
    bullet_point_description: [] as DescriptionList[],
    is_display: false,
should_recommend: false,
    category: '',
    relations: {
      causes: {
        symptoms: [],
      },
      is_indigestable_of: {
        diets: [],
      },
    },
  };

  const form = useForm({
    initialValues,
    // validate: yupResolver(schema),
  });

  useEffect(() => {
    if (!item._id) {
      form.setValues(initialValues);
    } else {
      form.setValues({
        name: item.name,
        preferred_name: item.preferred_name,
        abbreviation: item.abbreviation,
        keywords: item.keywords,
        symptoms: item.symptoms,
        description: item.description,
        one_line_description: item.one_line_description,
        bullet_point_description: cloneDeep(item.bullet_point_description) ?? ([] as DescriptionList[]),
        is_display: item.is_display,
should_recommend: item.should_recommend,
        category: item.category,
        relations: {
          causes: {
            symptoms: item.relations.causes.symptoms.map((s: any) => s._id),
          },
          is_indigestable_of: {
            diets: item.relations.is_indigestable_of.diets,
          },
        },
      });
    }
  }, []);

  const { data: symptomData, isLoading: isSymptomsLoading } = kbApi.endpoints.getSymptoms.useQuery({ version });
  const [updateMetabolism, { isLoading }] = kbApi.endpoints.updateMetabolism.useMutation();
  const { data: dietsData, isLoading: isDietsLoading } = kbApi.endpoints.getDiets.useQuery({ version });

  const loading = isSymptomsLoading || isDietsLoading;
  if (loading) {
    return <Loading />;
  }

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateMetabolism({
      id: item._id || 'new',
      version: version,
      ...values,
    });
    await item.refetch();

    if (isCreating) {
      navigate(`/kb/${version}/metabolisms`);
    }
  }

  function handleCreateKeyword(keyword: string) {
    form.insertListItem('keywords', keyword);
  }

  const symptoms = itemsToDropdown(symptomData);
  const diets = itemsToDropdown(dietsData);

  return (
    <form ref={formRef} className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Grid.Col span={2} order={2}>
          <MetaHeader
            collectionName="Metabolism"
            createdAt={item.createdAt}
            updatedAt={item.updatedAt}
            //@ts-ignore
            form={form}
            saveCallback={form.onSubmit(handleSubmit)}
          />
        </Grid.Col>
        <Grid.Col span={10} order={1}>
        <Box p="lg" className={classes.container} miw={{ base:'550px', md: '800px'}}>
            <Box className={classes.section}>
              <Flex justify="space-between" align="center">
                <Text size="lg" mb="md">
                  Details
                </Text>
                <Badge radius="sm" variant="filled" size="lg">
                  {version}
                </Badge>
              </Flex>
              <Flex>
                <TextInput
                  className={classes.fieldCol}
                  label="Name"
                  placeholder="Enter the name..."
                  {...form.getInputProps('name')}
                />
                <TextInput ml="xl" className={classes.fieldCol} label="ID" disabled value={item._id} />
              </Flex>
              <Flex mt="lg">
                <Select
                  className={classes.fieldCol}
                  label="Category"
                  placeholder="Enter the category..."
                  data={CATEGORIES}
                  {...form.getInputProps('category')}
                />
                <TextInput
                  ml="xl"
                  className={classes.fieldCol}
                  label="Abbreviation"
                  placeholder="Enter the abbreviation..."
                  {...form.getInputProps('abbreviation')}
                />
              </Flex>
              <Flex mt="md">
                <TextInput
                  className={classes.fieldCol}
                  label="Preferred name"
                  placeholder="Enter the preferred name..."
                  {...form.getInputProps('preferred_name')}
                />
              </Flex>
            </Box>

            <Box className={classes.section}>
              <Flex direction="column" gap="lg">
                <GeneralDescriptionsForm
                  //@ts-ignore
                  form={form}
                />

                <Label>Bulleted Description List</Label>
                <BulletPointDescriptionsForm
                  //@ts-ignore
                  form={form}
                />
              </Flex>
            </Box>

            <Box className={classes.section}>
              <Text size="lg" mb="md">
                Relationships
              </Text>
              <Text size="md" ml="md">
                Causes:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Symptoms"
                  placeholder="Select the symptoms..."
                  data={symptoms}
                  {...form.getInputProps('relations.causes.symptoms')}
                />
              </Flex>
              <Text size="md" mt="md" ml="md">
                Is indegestible of:
              </Text>
              <Flex>
                <MultiSelect
                  searchable
                  ml="xl"
                  className={classes.multiselect}
                  label="Diets"
                  placeholder="Select the diets..."
                  data={diets}
                  {...form.getInputProps('relations.is_indigestable_of.diets')}
                />
              </Flex>
            </Box>

            <Box>
              <MultiSelect
                searchable
                creatable
                mt="lg"
                className={classes.multiselect}
                label="Keywords"
                placeholder="Enter the keywords..."
                data={form.values.keywords}
                getCreateLabel={() => 'Create keyword'}
                {...form.getInputProps('keywords')}
              />
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
