export function getColorBySampleStatus(status: string) {
    switch (status) {
      case 'Pending':
        return 'red';
      case 'Processing':
        return 'yellow';
      case 'Ready for Review':
        return 'green';
      default:
        return 'blue';
    }
  }