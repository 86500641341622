import { Box, Text, createStyles, Flex, Grid, TextInput, Checkbox, Tabs, Button } from '@mantine/core';
import providerApi from 'services/provider';
import { useForm } from '@mantine/form';
import { useEffect, useRef } from 'react';
import MetaHeader from 'components/SharedForms/MetaHeader';
import { cloneDeep } from 'lodash';
import UserSearchPickerModal from 'components/UserSearchPickerModal';
import { useDisclosure } from '@mantine/hooks';
import { IPickerUser } from 'services/userV2';
import UserTable from 'components/UserTable';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { Provider } from 'types/provider/providerApiTypes';

interface ProviderFormProps {
  provider: Provider;
}

export default function ProviderForm({ provider }: ProviderFormProps) {
  const { classes } = useStyles();
  const formRef = useRef(null);
  const { providerId } = useParams();
  const [staffModalOpen, { close: closeStaff, open: openStaff }] = useDisclosure(false);
  const [clientModalOpen, { close: closeClient, open: openClient }] = useDisclosure(false);

  const mapProviderToForm = (provider: Provider) => ({
    id: provider.id,
    name: provider.name,
    email: provider.email,
    phone: provider.phone,
    shopifyOrderCode: provider.shopifyOrderCode,
    orderUrl: provider.orderUrl,
    providerStaff: provider.providerStaff,
    providerClients: provider.providerClients,
    preferences: cloneDeep(provider.preferences),
    createdAt: provider.createdAt,
  });
  const form = useForm({ initialValues: mapProviderToForm(provider) });
  useEffect(() => {
    form.setValues(mapProviderToForm(provider));
  }, [provider]);

  const pid: number = provider.id;

  const [updateProvider, { isLoading: isUpdateProviderLoading }] = providerApi.endpoints.updateProvider.useMutation();
  const [assignStaff, { isLoading: assignStaffLoading }] = providerApi.endpoints.assignStaff.useMutation();
  const [removeStaff, { isLoading: removeStaffLoading }] = providerApi.endpoints.removeStaff.useMutation();
  const [assignClient, { isLoading: assignClientLoading }] = providerApi.endpoints.assignClient.useMutation();
  const [removeClient, { isLoading: removeClientLoading }] = providerApi.endpoints.removeClient.useMutation();
  const [addOrderCode, { isLoading: addOrderCodeLoading, error: orderCodeError }] =
    providerApi.endpoints.updateOrderCode.useMutation();

  const loading =
    isUpdateProviderLoading || assignStaffLoading || removeStaffLoading || assignClientLoading || removeClientLoading;

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateProvider({
      id: pid,
      ...values,
    });
  }

  async function handleUpdateOrderCode() {
    await addOrderCode({ id: pid, shopifyOrderCode: form.values.shopifyOrderCode });

    if (orderCodeError && 'data' in orderCodeError) {
      notifications.show({
        title: 'Error',
        message: 'There was an error updating the order code',
        color: 'red',
      });
    } else {
      notifications.show({
        title: 'Success',
        message: 'Order code updated',
        color: 'green',
      });
    }
  }

  async function addStaffCallback(newStaff: IPickerUser) {
    await assignStaff({ id: providerId, staffId: newStaff.id });
  }

  async function removeStaffCallback(staff: IPickerUser) {
    await removeStaff({ id: providerId, staffId: staff.id });
  }

  async function addClientCallback(newClient: IPickerUser) {
    await assignClient({ id: providerId, clientId: newClient.id });
  }

  async function removeClientCallback(client: IPickerUser) {
    await removeClient({ id: providerId, clientId: client.id });
  }

  function getName(firstName: string | undefined, lastName: string | undefined) {
    if (!firstName && !lastName) {
      return 'N/A';
    } else {
      return `${firstName} ${lastName}`;
    }
  }
  return (
    <form ref={formRef} onSubmit={form.onSubmit(handleSubmit)}>
      <Grid grow>
        <Grid.Col span={2} order={2}>
          <MetaHeader
            isDisplay={false}
            collectionName="Provider"
            createdAt={provider.createdAt.toString()}
            updatedAt={provider.updatedAt.toString()}
            //@ts-ignore
            form={form}
            saveCallback={form.onSubmit(handleSubmit)}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col span={10} order={1}>
          <Box p="lg" className={classes.container} miw={{ base: '550px', md: '800px' }}>
            <Box className={classes.section}>
              <Flex justify="space-between" align="center">
                <Text size="lg" mb="md">
                  Details
                </Text>
              </Flex>
              <Flex gap="xl" justify="space-between" align="center">
                <TextInput
                  className={classes.fieldCol}
                  label="Name"
                  placeholder="Enter the name..."
                  {...form.getInputProps('name')}
                />
                <TextInput className={classes.fieldCol} label="ID" disabled value={provider.id} />
              </Flex>
              <Flex mt="lg" gap="xl" justify="space-between" align="center">
                <TextInput
                  className={classes.fieldCol}
                  label="Contact Email"
                  placeholder="Enter the contact email..."
                  {...form.getInputProps('email')}
                />
                <TextInput
                  className={classes.fieldCol}
                  label="Phone"
                  placeholder="Enter the phone number..."
                  {...form.getInputProps('phone')}
                />
              </Flex>
              <Flex mt="lg" gap="md" align="center">
                <TextInput
                  className={classes.fieldCol}
                  label="Order Code"
                  placeholder="Enter the order code..."
                  {...form.getInputProps('shopifyOrderCode')}
                />
                <Button
                  mt="lg"
                  variant="outline"
                  color="blue"
                  onClick={handleUpdateOrderCode}
                  loading={addOrderCodeLoading}
                >
                  Update Code
                </Button>
              </Flex>
              <Flex mt="lg" gap="md" align="center">
                <TextInput
                  className={classes.fieldCol}
                  label="Order URL"
                  placeholder="Enter the Shopify Order URL"
                  {...form.getInputProps('orderUrl')}
                />
              </Flex>
            </Box>

            <Box>
              <Text size="lg" mb="md">
                Preferences
              </Text>
              <Flex gap="md" wrap="wrap">
                <Checkbox
                  label="Manual Report Release"
                  {...form.getInputProps('preferences.manualReportRelease', { type: 'checkbox' })}
                />
                <Checkbox
                  label="Notify Client When Report Ready"
                  {...form.getInputProps('preferences.notifyClientWhenReportReady', { type: 'checkbox' })}
                />
                <Checkbox
                  label="Notify Client When Sample Received"
                  {...form.getInputProps('preferences.notifyClientWhenSampleReceived', { type: 'checkbox' })}
                />
                <Checkbox
                  label="Notify Provider When Report Ready"
                  {...form.getInputProps('preferences.notifyProviderWhenReportReady', { type: 'checkbox' })}
                />
              </Flex>
            </Box>

            <Box mt="xl" className={classes.section}>
              <Text size="lg" mb="md">
                Personnel
              </Text>
              <Flex direction="column">
                <Tabs variant="outline" defaultValue="staff">
                  <Tabs.List>
                    <Tabs.Tab value="staff">Staff</Tabs.Tab>
                    <Tabs.Tab value="patients">Patients</Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="staff" pt="md">
                    <Grid gutter="md">
                      <Grid.Col span={6}>
                        <UserTable users={form.values.providerStaff.map((staff: any) => staff.user)} />
                      </Grid.Col>
                      <Grid.Col span={3} offset={3}>
                        <Button onClick={openStaff} variant="outline" color="blue">
                          Edit Staff
                        </Button>
                        <UserSearchPickerModal
                          defaultValues={form.values.providerStaff.map((staff: any) => staff.user)}
                          opened={staffModalOpen}
                          close={closeStaff}
                          addUserCallback={addStaffCallback}
                          removeUserCallback={removeStaffCallback}
                        />
                      </Grid.Col>
                    </Grid>
                  </Tabs.Panel>
                  <Tabs.Panel value="patients" pt="md">
                    <Grid>
                      <Grid.Col span={6}>
                        <UserTable users={form.values.providerClients.map((client: any) => client.user) ?? []} />
                      </Grid.Col>
                      <Grid.Col span={3} offset={3}>
                        <Button onClick={openClient} variant="outline" color="blue">
                          Edit Clients
                        </Button>
                        <UserSearchPickerModal
                          defaultValues={form.values.providerClients.map((client: any) => client.user)}
                          opened={clientModalOpen}
                          close={closeClient}
                          addUserCallback={addClientCallback}
                          removeUserCallback={removeClientCallback}
                        />
                      </Grid.Col>
                    </Grid>
                  </Tabs.Panel>
                </Tabs>
              </Flex>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
