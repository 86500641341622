import React from 'react';
import { Box, createStyles } from '@mantine/core';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Body({ children, className }: Props) {
    const { classes, cx } = useStyles();

    return <Box className={cx(classes.container, className)}>{children}</Box>;
}

const useStyles = createStyles((theme) => ({
    container: {
        maxWidth: '1440px',
        marginTop: theme.spacing.md,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
    },
}));
