import { Flex, createStyles } from '@mantine/core';

import Row from 'components/Row';
import TreatmentActions from 'components/KB/TreatmentActions';
import TreatmentFilter from 'components/KB/TreatmentFilter';
import TreatmentResults from 'components/KB/TreatmentResults';

export default function Treatments() {
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <Flex direction="column">
                <TreatmentActions />
                <TreatmentFilter />
            </Flex>
            <TreatmentResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
