import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
    AppShell,
    Header,
    Flex,
    Text,
    MediaQuery,
    Burger,
    useMantineTheme,
} from '@mantine/core';
import PortalNavbar from 'components/Navbar';
import Logo from 'components/Logo';
import LogoutButton from 'components/LogoutButton';

export default function Home() {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);

    const [version, setVersion] = useState('');

    useEffect(() => {
        fetch('/version.json')
            .then((response) => response.json())
            .then((data) => setVersion(data.version));
    }, []);

    return (
        <AppShell
            styles={{
                main: {
                    background:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={<PortalNavbar opened={opened} />}
            header={
                <Header height={60} p="md">
                    <Flex align="center">
                        <Flex align="center" h="100%" w="100%">
                            <MediaQuery
                                largerThan="sm"
                                styles={{ display: 'none' }}
                            >
                                <Burger
                                    opened={opened}
                                    onClick={() => setOpened((o) => !o)}
                                    size="sm"
                                    color={theme.colors.gray[6]}
                                    mr="xl"
                                />
                            </MediaQuery>
                            <Logo />
                            <Text size="lg" weight={500} ml="sm">
                                Portal
                            </Text>
                            <Text size="sm" weight={400} ml="sm">
                                v{version}
                            </Text>
                        </Flex>
                        <Flex justify="end">
                            <LogoutButton />
                        </Flex>
                    </Flex>
                </Header>
            }
        >
            <Outlet />
        </AppShell>
    );
}
