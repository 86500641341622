import React from 'react';
import { Table, Text, createStyles } from '@mantine/core';
import { IPickerUser } from 'services/userV2';
import { getName } from 'util/transforms';

interface Props {
  users: IPickerUser[];
}

export default function UserTable({ users }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Table striped withBorder>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users.length > 0 &&
          users.map((user: IPickerUser, i: number) => {
            return (
              <tr key={i}>
                <td>{user.id}</td>
                <td>
                  <Text ml="md">{getName(user.firstName, user.lastName)}</Text>
                </td>
                <td>{user.email}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
}));
