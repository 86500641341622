import { Flex, createStyles } from '@mantine/core';

import Row from 'components/Row';
import LifestylesActions from '../components/KB/LifestylesActions';
import LifestyleResults from '../components/KB/LifestyleResults';
import LifestylesFilter from '../components/KB/LifestylesFilter';

export default function Lifestyles() {
  const { classes } = useStyles();

  return (
    <Row className={classes.container}>
      <Flex direction="column">
        <LifestylesActions />
        <LifestylesFilter />
      </Flex>
      <LifestyleResults />
    </Row>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: theme.spacing.lg,
  },
}));
