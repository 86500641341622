import { configureStore } from '@reduxjs/toolkit';

import userSlice from 'slices/user';
import kbSlice from 'slices/kb';
import homeSlice from 'slices/home';
import { emptySplitApi } from 'services/emptyApi';

export const store: any = configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        user: userSlice.reducer,
        kb: kbSlice.reducer,
        home: homeSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(emptySplitApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
