import React, { useState, useRef } from 'react';
import {
    Box,
    Flex,
    Title,
    Text,
    Button,
    createStyles,
    Col,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import InsightForm from './InsightForm';

export default function InsightCreateItem(item: any) {
    const [isHidden, setIsHidden] = useState(false);
    const navigate = useNavigate();
    const { classes } = useStyles({ isHidden });
    let childFormRef = null as any;

    function handleHideClick() {
        setIsHidden(!isHidden);
    }

    function handleSaveClick() {
        childFormRef.triggerSyntheticSubmit();
        item.handleSaveClick();
    }

    function handleCancelClick() {
        item.handleSaveClick();
    }

    const handleChildFormRef = (ref: any) => {
        childFormRef = ref;
    };

    return (
        <Box className={classes.container}>
            <Row className={classes.header}>
                <Title className={classes.title}>New Insight </Title>
                <Button
                    mr="md"
                    size="sm"
                    className={classes.hideButton}
                    onClick={handleHideClick}
                >
                    {isHidden ? 'Show' : 'Hide'}
                </Button>
                <Button
                    mr="md"
                    size="sm"
                    className={classes.hideButton}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    size="sm"
                    variant="light"
                    leftIcon={<FontAwesomeIcon icon={faSave} />}
                    onClick={handleSaveClick}
                >
                    Save
                </Button>
            </Row>

            <Box className={classes.body}>
                <InsightForm {...item} forwardRef={handleChildFormRef} />
            </Box>
        </Box>
    );
}

interface StyleProps {
    isHidden: boolean;
}

const useStyles = createStyles((theme, { isHidden }: StyleProps) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.lg,
        background: 'white',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
    },
    title: {
        marginRight: 'auto',
        fontSize: theme.fontSizes.xl,
    },
    header: {
        alignItems: 'center',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    body: {
        display: isHidden ? 'none' : 'initial',
        maxHeight: '800px',
        overflow: 'auto',
    },
    hideButton: {
        background: theme.colors.gray[1],
        color: theme.colors.gray[6],
    },
}));
