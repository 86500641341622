import { Condition, Metabolism, Treatment, Diet, Symptom, Lifestyle } from 'types/kb/prerelease';
import { emptySplitApi } from './emptyApi';

interface DisplayedResponse {
    conditions: Condition[],
    metabolisms: Metabolism[],
    treatments: Treatment[],
    diets: Diet[],
    symptoms: Symptom[],
    lifestyles?: Lifestyle[]
}

const kbApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDisplayed: builder.query<DisplayedResponse, any>({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/displayed`,
                headers: {
                }
            }),
        }),
        searchPapers: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/papers`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        getPaper: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/paper/${id}`,
            }),
        }),
        updatePaperFlagged: builder.mutation({
            query: ({ id, flagged, version }) => ({
                url: `/api/portal/kb/${version}/paper/${id}/flagged`,
                method: 'PUT',
                body: { flagged },
            }),
        }),
        updateInsight: builder.mutation({
            query: ({ id, kbVersion, ...insight }) => ({
                url: `/api/portal/kb/${kbVersion}/insight/${id}`,
                method: 'PUT',
                body: { insight },
            }),
        }),
        createInsight: builder.mutation({
            query: ({ insight, kbVersion }) => ({
                url: `/api/portal/kb/${kbVersion}/insight`,
                method: 'POST',
                body: { insight },
            }),
        }),

        searchConditions: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/conditions`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateCondition: builder.mutation({
            query: ({ id, version, ...condition }) => ({
                url: `/api/portal/kb/${version}/condition/${id}`,
                method: 'POST',
                body: { condition },
            }),
        }),
        getCondition: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/condition/${id}`,
            }),
        }),
        getConditions: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/condition/all`,
            }),
        }),

        searchSymptoms: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/symptoms`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateSymptom: builder.mutation({
            query: ({ id, version, ...symptom }) => ({
                url: `/api/portal/kb/${version}/symptom/${id}`,
                method: 'POST',
                body: { symptom },
            }),
        }),
        getSymptom: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/symptom/${id}`,
            }),
        }),
        getSymptoms: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/symptom/all`,
            }),
        }),
        searchSensitivities: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/sensitivities`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateSensitivity: builder.mutation({
            query: ({ id, version, ...sensitivity }) => ({
                url: `/api/portal/kb/${version}/sensitivity/${id}`,
                method: 'POST',
                body: { sensitivity },
            }),
        }),
        getSensitivity: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/sensitivity/${id}`,
            }),
        }),
        getSensitivities: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/sensitivity/all`,
            }),
        }),

        searchDiets: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/diets`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateDiet: builder.mutation({
            query: ({ id, version, ...diet }) => ({
                url: `/api/portal/kb/${version}/diet/${id}`,
                method: 'POST',
                body: { diet },
            }),
        }),
        getDiet: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/diet/${id}`,
            }),
        }),
        getDiets: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/diet/all`,
            }),
        }),

        searchTreatments: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/treatments`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateTreatment: builder.mutation({
            query: ({ id, version, ...treatment }) => ({
                url: `/api/portal/kb/${version}/treatment/${id}`,
                method: 'POST',
                body: { treatment },
            }),
        }),
        getTreatment: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/treatment/${id}`,
            }),
        }),
        getTreatments: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/treatment/all`,
            }),
        }),

        searchMetabolisms: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/metabolisms`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateMetabolism: builder.mutation({
            query: ({ id, version, ...metabolism }) => ({
                url: `/api/portal/kb/${version}/metabolism/${id}`,
                method: 'POST',
                body: { metabolism },
            }),
        }),
        getMetabolism: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/metabolism/${id}`,
            }),
        }),
        getMetabolisms: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/metabolism/all`,
            }),
        }),

        searchOrganisms: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/organisms`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateOrganism: builder.mutation({
            query: ({ id, version, ...organism }) => ({
                url: `/api/portal/kb/${version}/organism/${id}`,
                method: 'POST',
                body: { organism },
            }),
        }),
        getOrganism: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/organism/${id}`,
            }),
        }),
        getOrganisms: builder.query({
            query: ({ name, version }) => ({
                url: `/api/portal/kb/${version}/organism/all`,
            }),
        }),
        getOrganismsFromIds: builder.query({
            query: ({ ids, version }) => ({
                url: `/api/portal/kb/${version}/organism/filters`,
                params: { ids },
            }),
        }),

        searchLifestyles: builder.mutation({
            query: ({ query, filters, page, sort, version }) => ({
                url: `/api/portal/kb/${version}/search/lifestyles`,
                method: 'POST',
                body: { query, filters, page, sort },
            }),
        }),
        updateLifestyle: builder.mutation({
            query: ({ id, version, ...lifestyle }) => ({
                url: `/api/portal/kb/${version}/lifestyle/${id}`,
                method: 'POST',
                body: { lifestyle },
            }),
        }),
        getLifestyle: builder.query({
            query: ({ id, version }) => ({
                url: `/api/portal/kb/${version}/lifestyle/${id}`,
            }),
        }),
        getLifestyles: builder.query({
            query: ({ version }) => ({
                url: `/api/portal/kb/${version}/lifestyle/all`,
            }),
        }),
    }),
});

export default kbApi;
