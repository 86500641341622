import React from 'react';
import { Box, createStyles } from '@mantine/core';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Column({ children, className }: Props) {
    const { classes, cx } = useStyles();

    return <Box className={cx(classes.container, className)}>{children}</Box>;
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));
