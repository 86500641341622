import { MantineTheme } from '@mantine/core';

const global = (theme: MantineTheme): any => ({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },

  body: {
    margin: 0,
    fontFamily: 'Sora, sans-serif',
    background: theme.colors.gray[0],
    color: theme.colors.dark[5],
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },

  'h1,h2,h3,h4,h5,h6': {
    // color: `${theme.colors.dark[7]} !important`,
  },

  a: {
    color: theme.colors.blue[6],
    '&:visited': {
      color: theme.colors.blue[6],
    },
    '&:hover': {
      color: theme.colors.blue[7],
    },
  },
});

export default global;
