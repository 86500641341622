import { createStyles, Divider, Navbar, NavLink } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Row from './Row';

interface Props {
    opened: boolean;
}

export default function PortalNavbar({ opened }: Props) {
    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    function handleNavClick(url: string) {
        navigate(url);
    }

    return (
        <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 175 }}>
            <Row className={classes.row}>
                <NavLink onClick={() => handleNavClick("/users")} label="Users" />
            </Row>
            <Row className={classes.row}>
                <NavLink onClick={() => handleNavClick("/providers")} label="Providers" />
            </Row>
            <Row className={classes.row}>
                <NavLink onClick={() => handleNavClick("/samples")} label="Samples" />
            </Row>
            <Divider />
            <Row className={classes.row}>
                <NavLink onClick={() => handleNavClick("/kb/conditions")} label="KB Search" />
            </Row>
            <Row className={classes.row}>
                <NavLink onClick={() => handleNavClick("/kb/lookup")} label="KB Lookup" />
            </Row>
        </Navbar>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        paddingTop: theme.spacing.lg,
        paddingBottom: theme.spacing.lg,
        background: 'white',
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1440px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
    },
    row: {
        alignItems: 'center',
        width: 'initial',
    },
    link: {
        marginRight: theme.spacing.sm,
        padding: theme.spacing.sm,
        color: `${theme.colors.dark[5]} !important`,
        fontSize: theme.fontSizes.sm,
        fontWeight: 700,
        textDecoration: 'none',
        borderRadius: '4px',

        '&:hover': {
            background: theme.colors.blue[0],
        },
    },
    disabled: {
        pointerEvents: 'none',
        color: `${theme.colors.gray[6]} !important`,
    },
}));
