import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

import Loading from 'pages/Loading';

interface Props {
  component: React.ComponentType;
}

export default function ProtectedRoute({ component }: Props) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
}