import { Flex, TextInput, createStyles, Textarea} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface FormValues {
  description: string;
  one_line_description: string;
}

export function GeneralDescriptionsForm({ form }: { form: UseFormReturnType<FormValues> }) {
  const { classes } = useStyles();

  return (
    <Flex direction="column" w='100%'>
      <Textarea
        className={classes.description}
        label="Description"
        minRows={10}
        placeholder="Enter the description..."
        {...form.getInputProps('description')}
      />
      <TextInput
        mt='lg'
        className={classes.description}
        label="One Line Description"
        placeholder="Enter the one line description..."
        {...form.getInputProps('one_line_description')}
      />
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  description: {
    width: '100%',
  },
}));
