export const admins = [
    'logan.bernard',
    'sha.zhan',
    'clay.yoo',
    'leo.grady',
    'rainey.dunham',
];

export const INSIGHT_TYPES = {
    SUBJECT_TYPES: [
        'adult (18-)',
        'child/adolescence (5-17)',
        'infant (0-4)',
        'skip',
        'other',
    ],
    TEST_TYPES: ['ileum', 'mucosa', 'stool', 'skip', 'other'],
    PAPER_TYPES: ['Type 1', 'Type 2'],
    STUDY_TYPES: [
        'randomized controlled trial',
        'clinical trial',
        'meta-analysis',
        'review',
        'skip',
        'other',
    ],
    SEQUENCING_TYPES: [
        '16s',
        '18s',
        'hsp60',
        'metagenomic/shotgun sequencing',
        'pyrosequencing',
        'amplicon sequencing',
        'illumina',
        'pcr',
        'qpcr',
        'rt-pcr',
        'rt-qpcr',
        'qiime',
        'hitchip',
        'spss',
        'fish',
        'skip',
        'other',
    ],

    ACTION_TYPES: ['Type 0', 'Type 1', 'Type 2', 'Type 3'],
    STATUSES: ['needs review', 'validated', 'archived', 'duplicate'],
};

export const SORT_OPTIONS = ['Creation Date', 'Updated Date', 'A-Z', 'Z-A'];
