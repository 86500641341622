import React, { useState } from 'react';
import { Box, Text, Title, Button, createStyles, Flex } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Loading from './Loading';
import Row from 'components/Row';
import Navbar from 'components/Navbar';
import Body from 'components/Body';
import BackButton from 'components/BackButton';
import Card from 'components/Card';
import Column from 'components/Column';
import Label from 'components/Label';
import PaperMeta from 'components/KB/PaperMeta';
import InsightItem from 'components/KB/InsightItem';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import InsightCreateItem from 'components/KB/InsightCreateItem';
import Forbidden from './Forbidden';
import { useAppSelector } from 'hooks';

export default function Paper() {
    const { paperId } = useParams();
    const { classes } = useStyles();
    const [isCreating, setIsCreating] = useState(false);
    const version = useAppSelector((state) => state.kb.version);

    const { data, isLoading, error, refetch } = kbApi.endpoints.getPaper.useQuery({
        id: paperId,
        version
    });
    const [updatePaperFlagged] =
        kbApi.endpoints.updatePaperFlagged.useMutation();

    if (error && ('data' in error)) {
        if (error.status === 403) {
            return <Forbidden />;
        }
    }

    if (isLoading) {
        return (
            <Loading />
        );
    }

    function handleCreateClick() {
        setIsCreating(true);
    }

    function handleSaveClick() {
        setIsCreating(false);
    }

    async function handleFlaggedClick(flagged: boolean) {
        await updatePaperFlagged({
            id: paperId,
            flagged,
            version
        });
        await refetch();
    }

    return (
        <Box>
            <Body>
                <Box className={classes.container}>
                    <Flex mb="md" justify="space-between" align="flex-end">
                        <BackButton to={-1} label="Back" />
                        <Flex>
                            {!data.flagged ? (
                                <Button
                                    mr="sm"
                                    variant="light"
                                    color="red"
                                    onClick={() => handleFlaggedClick(true)}
                                >
                                    Flag Paper
                                </Button>
                            ) : (
                                <Button
                                    mr="sm"
                                    variant="light"
                                    onClick={() => handleFlaggedClick(false)}
                                >
                                    Unflag Paper
                                </Button>
                            )}

                            <Button variant="light" onClick={handleCreateClick}>
                                Create Insight
                            </Button>
                        </Flex>
                    </Flex>
                    <Row className={classes.body}>
                        <Flex className={classes.paper} direction="column">
                            <PaperMeta {...data} />
                            <Flex p="lg" direction="column">
                                <Label>Abstract</Label>
                                <Flex mt="sm" direction="column">
                                    <Text mb="md">{data.paper_abstract}</Text>
                                    {/* {data.paper_abstract &&
                                        data.paper_abstract.map(
                                            (
                                                paragraph: string,
                                                idx: number
                                            ) => (
                                                <Text key={idx} mb="md">
                                                    {paragraph}
                                                </Text>
                                            )
                                        )} */}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Column>
                            {isCreating && (
                                <InsightCreateItem
                                    refetchPaper={refetch}
                                    handleSaveClick={handleSaveClick}
                                />
                            )}
                            {data.insights.length === 0 && !isCreating && (
                                <Card>
                                    <Title size="md">No Insights Found</Title>
                                </Card>
                            )}
                            {data.insights.map((insight: any, idx: number) => (
                                <InsightItem
                                    key={insight._id}
                                    idx={idx}
                                    refetchPaper={refetch}
                                    {...insight}
                                />
                            ))}
                        </Column>
                    </Row>
                </Box>
            </Body>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.lg,
    },
    body: {},
    paper: {
        width: '100%',
        // minWidth: '600px',
        // maxWidth: '600px',
        minHeight: '400px',
        maxHeight: '80vh',
        marginRight: theme.spacing.lg,
        marginBottom: theme.spacing.xl,
        overflow: 'auto',
        background: 'white',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.gray[3]}`,
    },
}));
