import React, { useEffect, useRef, useState } from 'react';
import {
    createStyles,
    TextInput,
    Textarea,
    Button,
    Checkbox,
    Select,
    Flex,
    Box,
    Text,
    MultiSelect,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import Label from 'components/Label';
import Loading from 'pages/Loading';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import { useAppSelector } from 'hooks';


const RANKS = [
    'no rank',
    'biotype',
    'clade',
    'class',
    'cohort',
    'family',
    'forma',
    'genus',
    'infraclass',
    'infraorder',
    'isolate',
    'kingdom',
    'order',
    'phylum',
    'serogroup',
    'serotype',
    'species',
    'species group',
    'species subgroup',
    'strain',
    'subclass',
    'subfamily',
    'subgenus',
    'subkingdom',
    'suborder',
    'subphylum',
    'subspecies',
    'superfamily',
    'superkingdom',
    'superorder',
    'tribe',
    'varietas',
];

export default function OrganismForm(item: any) {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const { user } = useAuth0();
    const formRef = useRef(null);
    const version = useAppSelector((state) => state.kb.version);
    const { organismId } = useParams();
    const [isCreating, setIsCreating] = useState(organismId === 'new');

    const initialValues = {
        name: '',
        rank: '',
        organism_id: '',
        abbreviation: '',
        keywords: [],
        symptoms: [],
        conditions: [],
        metabolisms: [],
        diets: [],
        description: '',
        category: '',
        one_line_description: '',
        is_probiotic: false,
        is_pathogen: false,
        relations: {
            causes: {
                symptoms: []
            }
        }
    };

    const form = useForm({
        initialValues,
        // validate: yupResolver(schema),
    });

    useEffect(() => {
        if (!item._id) {
            form.setValues(initialValues);
        } else {
            form.setValues({
                name: item.name,
                rank: item.rank,
                organism_id: item._id,
                abbreviation: item.abbreviation,
                keywords: item.keywords,
                symptoms: item.symptoms,
                conditions: item.conditions,
                metabolisms: item.metabolisms,
                diets: item.diets,
                description: item.description,
                one_line_description: item.one_line_description,
                is_probiotic: item.is_probiotic,
                is_pathogen: item.is_pathogen,
                category: item.category,
                relations: {
                    causes: {
                        symptoms: item.relations.causes.symptoms
                    }
                }
            });
        }
    }, []);

    const { data: symptomData, isLoading: isSymptomsLoading } =
        kbApi.endpoints.getSymptoms.useQuery({ version });
    const { data: conditionData, isLoading: isConditionsLoading } =
        kbApi.endpoints.getConditions.useQuery({ version });
    const { data: metabolismData, isLoading: isMetabolismsLoading } =
        kbApi.endpoints.getMetabolisms.useQuery({ version });
    const { data: dietData, isLoading: isDietsLoading } =
        kbApi.endpoints.getDiets.useQuery({ version });
    const [updateOrganism, { isLoading }] =
        kbApi.endpoints.updateOrganism.useMutation();

    const loading =
        isSymptomsLoading ||
        isConditionsLoading ||
        isMetabolismsLoading ||
        isDietsLoading;
    if (loading) {
        return <Loading />;
    }

    async function handleSubmit(values: any, event: any) {
        event.preventDefault();
        await updateOrganism({
            id: item._id || 'new',
            version: version,
            ...values,
        });
        await item.refetch();

        if (isCreating) {
            navigate(`/kb/${version}/organisms`);
        }
    }

    function handleCreateKeyword(keyword: string) {
        form.insertListItem('keywords', keyword);
    }

    const symptoms = itemsToDropdown(symptomData);

    return (
        <form
            ref={formRef}
            className={classes.form}
            onSubmit={form.onSubmit(handleSubmit)}
        >
            <Box p="lg" className={classes.container}>
                <Box className={classes.section}>
                    <Text size="lg" mb="md">
                        Details
                    </Text>
                    <Flex>
                        <TextInput
                            className={classes.fieldCol}
                            label="Name"
                            placeholder="Enter the name..."
                            {...form.getInputProps('name')}
                        />
                        <TextInput
                            ml="xl"
                            className={classes.fieldCol}
                            label="ID"
                            disabled={item._id}
                            {...form.getInputProps('organism_id')}
                        />
                    </Flex>
                    <Flex mt="lg">
                        <Select
                            className={classes.fieldCol}
                            label="Rank"
                            placeholder="Enter the rank..."
                            data={RANKS}
                            {...form.getInputProps('rank')}
                        />
                        <TextInput
                            ml="xl"
                            className={classes.fieldCol}
                            label="Abbreviation"
                            placeholder="Enter the abbreviation..."
                            {...form.getInputProps('abbreviation')}
                        />
                    </Flex>
                    <Flex mt="lg">
                        <Checkbox
                            label="Pathogen"
                            className={classes.fieldCol}
                            {...form.getInputProps('is_pathogen', {
                                type: 'checkbox',
                            })}
                        />

                        <Checkbox
                            ml="xl"
                            className={classes.fieldCol}
                            label="Probiotic"
                            {...form.getInputProps('is_probiotic', {
                                type: 'checkbox',
                            })}
                        />
                    </Flex>
                    <Flex mt="lg">
                        <Textarea
                            className={classes.description}
                            label="Description"
                            minRows={6}
                            placeholder="Enter the description..."
                            {...form.getInputProps('description')}
                        />
                    </Flex>
                    <Flex mt="md">
                        <TextInput
                            className={classes.description}
                            label="One-Line Description"
                            placeholder="Enter the short description..."
                            {...form.getInputProps('one_line_description')}
                        />
                    </Flex>
                </Box>

                <Box className={classes.section}>
                    <Text size="lg" mb="md">
                        Relationships
                    </Text>
                    <Text size="md" ml="md">
                        Causes:
                    </Text>
                    <Flex>
                        <MultiSelect
                            searchable
                            ml="xl"
                            className={classes.multiselect}
                            label="Symptoms"
                            placeholder="Select the symptoms..."
                            data={symptoms}
                            {...form.getInputProps('relations.causes.symptoms')}
                        />
                    </Flex>
                </Box>

                <Box>
                    <MultiSelect
                        searchable
                        creatable
                        mt="lg"
                        className={classes.multiselect}
                        label="Keywords"
                        placeholder="Select the keywords..."
                        data={form.values.keywords}
                        getCreateLabel={() => 'Create keyword'}
                        {...form.getInputProps('keywords')}
                    />
                </Box>
            </Box>
            <Flex className={classes.pane} direction="column">
                <Text size="lg" mb="md">
                    Meta
                </Text>

                <Flex direction="column">
                    <Label>Created at</Label>
                    <Text>{moment(item.createdAt).format('LLL')}</Text>
                </Flex>

                <Flex mt="lg" direction="column">
                    <Label>Edited at</Label>
                    <Text>{moment(item.updatedAt).format('LLL')}</Text>
                </Flex>
                <Button
                    mt="sm"
                    variant="light"
                    type="submit"
                    loading={isLoading}
                >
                    Save Organism
                </Button>
            </Flex>
        </form>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: '400px',
        marginBottom: theme.spacing.xl,
        overflow: 'auto',
        background: 'white',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.gray[3]}`,
    },
    pane: {
        minWidth: '400px',
        maxWidth: '400px',
        height: 'fit-content',
        padding: theme.spacing.lg,
        marginLeft: theme.spacing.lg,
        marginBottom: theme.spacing.xl,
        background: 'white',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.gray[3]}`,
    },
    form: {
        display: 'flex',
        width: '100%',
    },
    section: {
        paddingBottom: theme.spacing.lg,
        marginBottom: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
    },
    fieldCol: {
        width: '50%',
    },
    description: {
        width: '100%',
    },
    multiselect: {
        width: '100%',
    },
}));
