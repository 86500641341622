import { Outlet } from 'react-router-dom';

import Body from 'components/Body';

export default function Samples() {
    
    return (
        <Body>
            <Outlet />
        </Body>
    );
}


