import React, { useImperativeHandle, useEffect, useRef, useState } from 'react';
import {
  createStyles,
  TextInput,
  NumberInput,
  Textarea,
  Button,
  Badge,
  Checkbox,
  Select,
  Flex,
  Box,
  Text,
  MultiSelect,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import Row from 'components/Row';
import Label from 'components/Label';
import Loading from 'pages/Loading';
import kbApi from 'services/kb';
import { itemsToDropdown } from 'util/transforms';
import { admins } from 'util/constants';
import { useAppSelector } from 'hooks';

export default function SensitivityForm(item: any) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const formRef = useRef(null);
  const version = useAppSelector((state) => state.kb.version);
  const { sensitivityId } = useParams();
  const [isCreating, setIsCreating] = useState(sensitivityId === 'new');

  const CATEGORIES : string[] = [];

  const initialValues = {
    name: '',
    abbreviation: '',
    symptoms: [],
    keywords: [],
    description: '',
    microbiome: '',
    is_display: false,
should_recommend: false,
    category: '',
  };

  const form = useForm({
    initialValues,
    // validate: yupResolver(schema),
  });

  useEffect(() => {
    if (!item._id) {
      form.setValues(initialValues);
    } else {
      form.setValues({
        name: item.name,
        abbreviation: item.abbreviation,
        symptoms: item.symptoms,
        keywords: item.keywords,
        description: item.description,
        microbiome: item.microbiome,
        is_display: item.is_display,
should_recommend: item.should_recommend,
        category: item.category,
      });
    }
  }, []);

  const { data: symptomData, isLoading: isSymptomsLoading } = kbApi.endpoints.getSymptoms.useQuery({ version });
  const [updateSensitivity, { isLoading }] = kbApi.endpoints.updateSensitivity.useMutation();

  const loading = isSymptomsLoading;
  if (loading) {
    return <Loading />;
  }

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await updateSensitivity({
      id: item._id || 'new',
      version: version,
      ...values,
    });
    await item.refetch();

    if (isCreating) {
      navigate(`/kb/${version}/lifestyles`);
    }
  }

  function handleCreateKeyword(keyword: string) {
    form.insertListItem('keywords', keyword);
  }

  const symptoms = itemsToDropdown(symptomData);

  return (
    <form ref={formRef} className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Box p="lg" className={classes.container}>
        <Box className={classes.section}>
          <Flex justify="space-between" align="center">
            <Text size="lg" mb="md">
              Details
            </Text>
            <Badge radius="sm" variant="filled" size="lg">
              {version}
            </Badge>
          </Flex>
          <Flex>
            <TextInput
              className={classes.fieldCol}
              label="Name"
              placeholder="Enter the name..."
              {...form.getInputProps('name')}
            />
            <TextInput ml="xl" className={classes.fieldCol} label="ID" disabled value={item._id} />
          </Flex>
          <Flex mt="lg">
            <Select
              className={classes.fieldCol}
              label="Category"
              placeholder="Enter the category..."
              data={CATEGORIES}
              {...form.getInputProps('category')}
            />
            <TextInput
              ml="xl"
              className={classes.fieldCol}
              label="Abbreviation"
              placeholder="Enter the abbreviation..."
              {...form.getInputProps('abbreviation')}
            />
          </Flex>
          <Flex mt="lg">
            <Textarea
              className={classes.description}
              label="Description"
              minRows={6}
              placeholder="Enter the description..."
              {...form.getInputProps('description')}
            />
          </Flex>
          <Flex mt="lg">
            <Textarea
              className={classes.description}
              label="Microbiome Relationship"
              minRows={6}
              placeholder="Enter the microbiome relationship..."
              {...form.getInputProps('microbiome')}
            />
          </Flex>
        </Box>

        <Box>
          <Text size="lg" mb="md">
            Associations
          </Text>
          <MultiSelect
            searchable
            className={classes.multiselect}
            label="Symptoms"
            placeholder="Select the symptoms..."
            data={symptoms}
            {...form.getInputProps('symptoms')}
          />
          <MultiSelect
            mt="lg"
            searchable
            creatable
            className={classes.multiselect}
            label="Keywords"
            placeholder="Select the keywords..."
            data={form.values.keywords}
            getCreateLabel={() => 'Create keyword'}
            {...form.getInputProps('keywords')}
          />
        </Box>
      </Box>
      <Flex className={classes.pane} direction="column">
        <Text size="lg" mb="md">
          Meta
        </Text>

        <Flex direction="column">
          <Label>Created at</Label>
          <Text>{moment(item.createdAt).format('LLL')}</Text>
        </Flex>

        <Flex mt="lg" direction="column">
          <Label>Edited at</Label>
          <Text>{moment(item.updatedAt).format('LLL')}</Text>
        </Flex>

        <Checkbox
          mt="lg"
          mb="sm"
          label="Display"
          {...form.getInputProps('is_display', { type: 'checkbox' })}
        />
        <Button variant="light" type="submit" loading={isLoading}>
          Save Sensitivity
        </Button>
      </Flex>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '400px',
    marginBottom: theme.spacing.xl,
    overflow: 'auto',
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '400px',
    maxWidth: '400px',
    height: 'fit-content',
    padding: theme.spacing.lg,
    marginLeft: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {
    display: 'flex',
    width: '100%',
  },
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
