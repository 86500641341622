import { Box, createStyles, Flex } from '@mantine/core';
import { useParams } from 'react-router-dom';

import Loading from '../../pages/Loading';
import Forbidden from '../../pages/Forbidden';
import providerApi from 'services/provider';
import BackButton from 'components/BackButton';
import Body from 'components/Body';
import Row from 'components/Row';
import ProviderForm from './ProviderForm';

export default function EditProviderContainer() {
  const { classes } = useStyles();
  const { providerId } = useParams();

  const { data: provider, isLoading, error } = providerApi.endpoints.getProvider.useQuery({ id: providerId });

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !provider) {
    return <Loading />;
  }

  return (
    <>
      <Flex mb="md" justify="space-between" align="flex-end">
        <BackButton to={-1} label="Back" />
      </Flex>
      <ProviderForm provider={provider} />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
