import React from 'react';
import { Button } from '@mantine/core';
import { getToken } from 'services/token';

const API_URL =
    process.env.NODE_ENV === 'production'
        ? window.env.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL;

const DownloadSampleDatesButton: React.FC = () => {
    const handleDownload = async () => {
        try {
            const response = await fetch(
                API_URL + '/api/portal/user/all/sample-dates/download-csv',
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const contentDisposition = response.headers.get(
                    'Content-Disposition'
                );
                let filename = 'sample-dates.csv'; // Fallback filename
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(
                        /filename="?([^"]+)"?$/
                    );
                    if (filenameMatch && filenameMatch.length > 1) {
                        filename = filenameMatch[1];
                    }
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); // Use the server-specified filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Failed to download file:', response.statusText);
                window.alert('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            window.alert('Error downloading file: ' + error);
        }
    };

    return (
        <Button color="dark" mt="sm" onClick={handleDownload} variant="outline" disabled={true}>
            Download CSV
        </Button>
    );
};

export default DownloadSampleDatesButton;
