import React, { useState } from 'react';
import { Box, Text, Title, Button, createStyles, Flex } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Loading from './Loading';
import Row from 'components/Row';
import Navbar from 'components/Navbar';
import Body from 'components/Body';
import BackButton from 'components/BackButton';
import SymptomForm from 'components/KB/SymptomForm';
import kbApi from 'services/kb';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import kbSlice from 'slices/kb';

export default function Symptom() {
    const dispatch = useDispatch();
    const { symptomId, version } = useParams();
    const { classes } = useStyles();
    const [isCreating, setIsCreating] = useState(false);
    const stateVersion = useAppSelector((state) => state.kb.version);
    
    if (version !== undefined) {
      if (version !== stateVersion) {
          dispatch(kbSlice.actions.setVersion(version));
      }
    }
    const { data, isLoading, refetch } = kbApi.endpoints.getSymptom.useQuery({
        id: symptomId,
        version
    });

    if (isLoading) {
        return (
            <Loading />
        );
    }

    function handleCreateClick() {
        setIsCreating(true);
    }

    function handleSaveClick() {
        setIsCreating(false);
    }

    return (
        <Box>
            <Body>
                <Box className={classes.container}>
                    <Flex mb="md" justify="space-between" align="flex-end">
                        <BackButton to={-1} label="Back" />
                    </Flex>
                    <Row className={classes.body}>
                        <SymptomForm refetch={refetch} {...data} />
                    </Row>
                </Box>
            </Body>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: theme.spacing.lg,
    },
    body: {},
}));
