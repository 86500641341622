import React from 'react';
import { Flex, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import MetabolismActions from 'components/KB/MetabolismActions';
import MetabolismFilter from 'components/KB/MetabolismFilter';
import MetabolismResults from 'components/KB/MetabolismResults';

export default function Metabolisms() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <Flex direction="column">
                <MetabolismActions />
                <MetabolismFilter />
            </Flex>
            <MetabolismResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
