import {
  Box,
  Checkbox,
  Button,
  createStyles,
} from '@mantine/core';
import { useDispatch } from 'react-redux';

import Label from 'components/Label';
import kbSlice from 'slices/kb';
import { useAppSelector } from 'hooks';

export default function LifestylesFilter() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.kb.filters.lifestyles);

  const handleCheckboxFilterChange = (filter: string) => (event: any) => {
    dispatch(
      kbSlice.actions.updateLifestyleFilter({
        filter,
        value: event.currentTarget.checked,
      })
    );
  };

  function handleClearClick() {
    dispatch(kbSlice.actions.clearLifestyleFilters());
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <Label>Options</Label>
        <Checkbox
          mt="xs"
          label="Display"
          size="sm"
          checked={filters.is_display}
          onChange={handleCheckboxFilterChange('is_display')}
        />
        <Button
          fullWidth
          mt="lg"
          variant="light"
          onClick={handleClearClick}
        >
          Clear Filters
        </Button>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
    maxWidth: '400px',
    marginRight: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
  },
  card: {
    padding: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    background: 'white',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '4px',
  },
}));
