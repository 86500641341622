import providerApi from 'services/provider';
import { Button, Modal, Text, createStyles } from '@mantine/core';
import { notifications } from '@mantine/notifications';


interface DeleteProviderModalProps {
  providerId: string;
  opened: boolean;
  close: () => void;
  callback: () => void;
}

export default function DeleteProviderModal({ providerId, opened, callback, close }: DeleteProviderModalProps) {
  const { classes } = useStyles();

  const [deleteProvider, { error: deleteError }] = providerApi.endpoints.deleteProvider.useMutation();


  async function handleConfirmedDeleteClick() {
    deleteProvider({ id: providerId });

    if (deleteError && 'data' in deleteError) {
        notifications.show({
            title: 'Error',
            message: 'There was an error deleting the provider',
            color: 'red',
        });
    } else {
        notifications.show({
            title: 'Success',
            message: 'Provider deleted',
            color: 'green',
        });
    }

    await callback();
  }
  

  return (
    <Modal opened={opened} onClose={close} title="Confirm Delete" centered>
      <Text size="md">Are you sure you want to delete this provider? This action cannot be undone.</Text>
      <Button mt="lg" variant="filled" color="red" fullWidth onClick={handleConfirmedDeleteClick}>
      Delete Provider
      </Button>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  fieldCol: {
    width: '75%',
  },
}));