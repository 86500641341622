import { Box, Title, Table, Text, createStyles, Group, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from 'pages/Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import Forbidden from 'pages/Forbidden';
import providerApi from 'services/provider';
import { Provider } from 'types/provider/providerApiTypes';
import { useDisclosure } from '@mantine/hooks';
import CreateProviderModal from './CreateProviderModal';

export default function AllProviders() {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const [opened, { close, open, toggle }] = useDisclosure(false);
    const { data, isLoading, error, refetch} = providerApi.endpoints.getAllProviders.useQuery(undefined);

    if (error && 'data' in error) {
        if (error.status === 403) {
            return <Forbidden />;
        }
    }

    if (isLoading || !data) {
        return <Loading />;
    }
    
    const numProviders = data.length;

    function handleClick(provider: any) {
        navigate(`/providers/${provider.id}`);
    }

    function toggleCreateModal() {
        toggle();
    }

    async function createProviderCallback() {
        close();
        await refetch();
    }

    return (
        <Box className={classes.container}>
            <Row className={classes.header}>
                <Column className={classes.box}>
                    <Label>Total providers</Label>
                    <Title>{numProviders}</Title>
                </Column>
                <Column className={classes.box}>
                    <Group position="center">
                        <CreateProviderModal opened={opened} callback={createProviderCallback} close={close} />
                        <Button onClick={toggleCreateModal}>Create Provider</Button>
                    </Group>
                </Column>
            </Row>

            <Table
                verticalSpacing="md"
                striped
                highlightOnHover               
            >
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((provider: Provider) => (
                        <tr
                            key={provider.id}
                            className={classes.row}
                            onClick={() => handleClick(provider)}
                        >
                            <td>
                                <Text ml='sm'>{provider.name}</Text>
                            </td>
                            <td>
                                <Text ml='sm'>{provider.email}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        margin: theme.spacing.lg,
    },
    box: {
        width: 'fit-content',
        padding: theme.spacing.md,
        marginRight: theme.spacing.lg,
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: '4px',
    },
    header: {
        marginBottom: theme.spacing.lg,
    },
    row: {
        cursor: 'pointer',
    },
}));
