import { Box, Button, Flex, Modal, Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';

import Loading from '../../pages/Loading';
import Forbidden from '../../pages/Forbidden';
import BackButton from 'components/BackButton';
import sampleApi from 'services/sample';
import SampleForm from './SampleForm';
import { useDisclosure } from '@mantine/hooks';

export default function EditSampleContainer() {
  const { sampleId } = useParams();
  const [debugOpened, { close: debugClose, open: debugOpen }] = useDisclosure(false);

  if (!sampleId) {
    return <Text>Sample ID not provided</Text>;
  }

  const { data: sample, isLoading, error } = sampleApi.endpoints.getSample.useQuery({ id: parseInt(sampleId) });

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !sample) {
    return <Loading />;
  }

  return (
    <>
      <Flex mb="md" justify="space-between">
        <BackButton to={-1} label="Back" />
      </Flex>
      <Modal opened={debugOpened} onClose={debugClose} size="100%">
        <Box>
          <Title order={3}>Debug Sample {sample.barcode}</Title>
          <pre>{JSON.stringify(sample, null, 3)}</pre>
        </Box>
      </Modal>

      <SampleForm sample={sample} />
      <Button onClick={debugOpen} variant="outline">
        Debug
      </Button>
    </>
  );
}
