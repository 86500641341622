export function setToken(accessToken: string) {
  localStorage.setItem('accessToken', accessToken);
}

export function getToken() {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken;
}

export function clearToken() {
  localStorage.removeItem('accessToken');
}

export function getAuthHeader(headers: Headers) {
  const accessToken = getToken();
  headers.set('Authorization', `Bearer ${accessToken}`);
  return headers;
}

