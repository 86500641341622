import { Box, Button, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Label from 'components/Label';

export default function LifestylesActions() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function handleCreateClick() {
    navigate('/kb/lifestyles/new');
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <Label>Actions</Label>
        <Button
          mt="xs"
          fullWidth
          size="lg"
          variant="light"
          onClick={handleCreateClick}
        >
          Create Lifestyle
        </Button>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
    maxWidth: '400px',
    marginRight: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
  },
  card: {
    padding: theme.spacing.lg,
    background: 'white',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '4px',
  },
}));
