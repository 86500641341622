import { emptySplitApi } from './emptyApi';

export interface IPickerUser {
    id: number
    firstName: string;
    lastName: string;
    email: string;
}

export interface SearchUsersResponse {
    items: IPickerUser[];
    total: number;
}

const userv2Api = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({   
        searchUsers: builder.query<SearchUsersResponse, string>({
            query: (query) => ({
                url: `/api/user/v2/search`,
                method: 'POST',
                body: { query },
            }),
        })
    }),
});

export default userv2Api;
