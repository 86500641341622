import React, { useState, useRef } from 'react';
import {
    Box,
    Flex,
    Title,
    Text,
    Button,
    Badge,
    createStyles,
    Col,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import InsightForm from './InsightForm';

export default function InsightItem(item: any) {
    const [isHidden, setIsHidden] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const { classes } = useStyles({ isHidden });
    let childFormRef = null as any;

    function handleHideClick() {
        setIsHidden(!isHidden);
    }

    function handleEditClick() {
        setIsHidden(false);
        setIsEditing(true);
    }

    function handleCancelClick() {
        setIsEditing(false);
    }

    function handleSaveClick() {
        childFormRef.triggerSyntheticSubmit();
        setIsEditing(false);
    }

    const handleChildFormRef = (ref: any) => {
        childFormRef = ref;
    };

    function getStatusColor() {
        if (item.status === 'needs review') {
            return 'red';
        } else if (item.status === 'archived' || item.status === 'duplicate') {
            return 'gray';
        } else {
            return 'blue';
        }
    }

    return (
        <Box className={classes.container}>
            <Row className={classes.header}>
                <Flex mr="auto" direction="column">
                    <Title className={classes.title}>
                        Insight {item.idx + 1}{' '}
                    </Title>
                    <Flex mt="xs">
                        {item.editor && (
                            <Badge mr="sm" radius="xs">
                                {item.editor}
                            </Badge>
                        )}
                        {item.status && (
                            <Badge mr="sm" radius="xs" color={getStatusColor()}>
                                {item.status}
                            </Badge>
                        )}
                    </Flex>
                </Flex>
                <Button
                    mr="md"
                    size="sm"
                    className={classes.hideButton}
                    onClick={handleHideClick}
                >
                    {isHidden ? 'Show' : 'Hide'}
                </Button>
                {isEditing ? (
                    <>
                        <Button
                            size="sm"
                            mr="md"
                            className={classes.hideButton}
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="sm"
                            variant="light"
                            leftIcon={<FontAwesomeIcon icon={faSave} />}
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <Button
                        size="sm"
                        variant="light"
                        leftIcon={<FontAwesomeIcon icon={faEdit} />}
                        type="submit"
                        form={`insight-form-${item._id}`}
                        onClick={handleEditClick}
                    >
                        Edit
                    </Button>
                )}
            </Row>

            <Box className={classes.body}>
                {isEditing ? (
                    <InsightForm {...item} forwardRef={handleChildFormRef} />
                ) : (
                    <Flex p="lg" direction="column">
                        <Box className={classes.section}>
                            <Text size="lg" mb="md">
                                Meta
                            </Text>
                            <Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Status</Label>
                                    <Text mt="sm" size="sm">
                                        {item.status}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex mt="lg">
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Editor</Label>
                                    <Text mt="sm" size="sm">
                                        {item.editor}
                                    </Text>
                                </Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Version</Label>
                                    <Text mt="sm" size="sm">
                                        {item.version}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex mt="lg">
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Created At</Label>
                                    <Text mt="sm" size="sm">
                                        {item.createdAt
                                            ? moment(item.createdAt).format(
                                                  'LLL'
                                              )
                                            : ''}
                                    </Text>
                                </Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Edited At</Label>
                                    <Text mt="sm" size="sm">
                                        {item.updatedAt
                                            ? moment(item.updatedAt).format(
                                                  'LLL'
                                              )
                                            : ''}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Box>

                        <Box className={classes.section}>
                            <Text size="lg" mb="md">
                                Details
                            </Text>
                            <Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Study Size</Label>
                                    <Text mt="sm" size="sm">
                                        {item.study_size}
                                    </Text>
                                </Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Study Type</Label>
                                    <Text mt="sm" size="sm">
                                        {item.study_type}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex mt="lg">
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Subject Type</Label>
                                    <Text mt="sm" size="sm">
                                        {item.subject_type}
                                    </Text>
                                </Flex>
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Test Type</Label>
                                    <Text mt="sm" size="sm">
                                        {item.test_type}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex mt="lg">
                                <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Sequencing Type</Label>
                                    <Text mt="sm" size="sm">
                                        {item.sequencing_type}
                                    </Text>
                                </Flex>
                                {/* <Flex
                                    direction="column"
                                    className={classes.fieldCol}
                                >
                                    <Label>Paper Type</Label>
                                    <Text mt="sm" size="sm">
                                        {item.paper_type}
                                    </Text>
                                </Flex> */}
                            </Flex>
                        </Box>

                        <Box className={classes.section}>
                            <Flex direction="column">
                                <Text size="lg">Diseases</Text>

                                <Label className={classes.label}>
                                    Conditions
                                </Label>
                                <Flex wrap="wrap">
                                    {item.diseases
                                        .filter(
                                            (disease: any) =>
                                                disease.collection ===
                                                'conditions'
                                        )
                                        .map((disease: any, idx: number) => (
                                            <Text
                                                key={`${idx}-${disease.name}`}
                                                className={classes.marker}
                                            >
                                                {disease.name}
                                            </Text>
                                        ))}
                                </Flex>

                                <Label className={classes.label}>
                                    Sensitivities
                                </Label>
                                <Flex wrap="wrap">
                                    {item.diseases
                                        .filter(
                                            (disease: any) =>
                                                disease.collection ===
                                                'sensitivities'
                                        )
                                        .map((disease: any, idx: number) => (
                                            <Text
                                                key={`${idx}-${disease.name}`}
                                                className={classes.marker}
                                            >
                                                {disease.name}
                                            </Text>
                                        ))}
                                </Flex>

                                <Label className={classes.label}>
                                    Symptoms
                                </Label>
                                <Flex wrap="wrap">
                                    {item.diseases
                                        .filter(
                                            (disease: any) =>
                                                disease.collection ===
                                                'symptoms'
                                        )
                                        .map((disease: any, idx: number) => (
                                            <Text
                                                key={`${idx}-${disease.name}`}
                                                className={classes.marker}
                                            >
                                                {disease.name}
                                            </Text>
                                        ))}
                                </Flex>
                            </Flex>
                        </Box>

                        <Box className={classes.section}>
                            <Flex direction="column">
                                <Text size="lg" mb="sm">
                                    Actions
                                </Text>
                                <Label className={classes.label}>Diets</Label>
                                <Flex wrap="wrap">
                                    {item.actions
                                        .filter(
                                            (item: any) =>
                                                item.collection === 'diets'
                                        )
                                        .map((action: any, idx: number) => (
                                            <Text
                                                key={`${idx}-${action.name}`}
                                                className={classes.marker}
                                            >
                                                {action.type}: {action.name}
                                            </Text>
                                        ))}
                                </Flex>
                                <Label className={classes.label}>
                                    Treatments
                                </Label>
                                <Flex wrap="wrap">
                                    {item.actions
                                        .filter(
                                            (item: any) =>
                                                item.collection === 'treatments'
                                        )
                                        .map((action: any, idx: number) => (
                                            <Text
                                                key={`${idx}-${action.name}`}
                                                className={classes.marker}
                                            >
                                                {action.type}: {action.name}
                                            </Text>
                                        ))}
                                </Flex>
                            </Flex>
                        </Box>

                        <Box className={classes.section}>
                            <Flex direction="column">
                                <Text size="lg" mb="sm">
                                    Metabolisms
                                </Text>
                                <Label className={classes.label}>Items</Label>
                                <Flex wrap="wrap">
                                    {item.metabolisms &&
                                        item.metabolisms.map(
                                            (item: any, idx: number) => (
                                                <Text
                                                    key={`${idx}-${item.name}`}
                                                    className={classes.marker}
                                                >
                                                    {item.name}
                                                </Text>
                                            )
                                        )}
                                </Flex>
                            </Flex>
                        </Box>

                        <Box className={classes.section}>
                            <Flex direction="column">
                                <Text size="lg">Organisms</Text>

                                <Flex direction="column">
                                    <Label className={classes.label}>
                                        Gain
                                    </Label>
                                    <Flex wrap="wrap">
                                        {item.microbes.gain_microbes.map(
                                            (organism: any, idx: number) => (
                                                <Text
                                                    key={`${idx}-${organism.name}`}
                                                    className={classes.marker}
                                                >
                                                    {organism.name} (
                                                    {organism._id})
                                                </Text>
                                            )
                                        )}
                                    </Flex>
                                </Flex>
                                <Flex direction="column">
                                    <Label className={classes.label}>
                                        Loss
                                    </Label>
                                    <Flex wrap="wrap">
                                        {item.microbes.loss_microbes.map(
                                            (organism: any, idx: number) => (
                                                <Text
                                                    key={`${idx}-${organism.name}`}
                                                    className={classes.marker}
                                                >
                                                    {organism.name}
                                                </Text>
                                            )
                                        )}
                                    </Flex>
                                </Flex>
                                <Flex direction="column">
                                    <Label className={classes.label}>
                                        Presence
                                    </Label>
                                    <Flex wrap="wrap">
                                        {item.microbes.presence_microbes.map(
                                            (organism: any, idx: number) => (
                                                <Text
                                                    key={`${idx}-${organism.name}`}
                                                    className={classes.marker}
                                                >
                                                    {organism.name}
                                                </Text>
                                            )
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>
                        <Box>
                            <Text size="lg" mb="md">
                                Comments
                            </Text>
                            <Flex>
                                <Flex direction="column">
                                    <Label>Notes</Label>
                                    <Text mt="sm" size="sm">
                                        {item.notes}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex mt="lg">
                                <Flex direction="column">
                                    <Label>Evidence</Label>
                                    <Text mt="sm" size="sm">
                                        {item.evidence}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Box>
                    </Flex>
                )}
            </Box>
        </Box>
    );
}

interface StyleProps {
    isHidden: boolean;
}

const useStyles = createStyles((theme, { isHidden }: StyleProps) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.lg,
        background: 'white',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
        fontSize: theme.fontSizes.xl,
    },
    meta: {
        marginLeft: theme.spacing.lg,
    },
    header: {
        alignItems: 'center',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    body: {
        display: isHidden ? 'none' : 'initial',
        maxHeight: '800px',
        overflow: 'auto',
    },
    section: {
        paddingBottom: theme.spacing.lg,
        marginBottom: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
    },
    marker: {
        width: 'fit-content',
        textTransform: 'capitalize',
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        marginRight: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
        fontSize: theme.fontSizes.sm,
        background: theme.colors.gray[1],
        borderRadius: '4px',
        '&:hover': {
            background: theme.colors.blue[0],
        },
    },
    label: {
        marginBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
    },
    fieldCol: {
        width: '50%',
        paddingRight: theme.spacing.md,
    },
    hideButton: {
        background: theme.colors.gray[1],
        color: theme.colors.gray[6],
    },
}));
