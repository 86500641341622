import React from 'react';
import { Flex, Loader as MantineLoader, createStyles } from '@mantine/core';

interface Props {
    children?: React.ReactNode;
    className?: string;
}

export default function Loader({ children, className }: Props) {
    const { classes, cx } = useStyles();

    return (
        <Flex justify="center" align="center" m="xl">
            <MantineLoader variant="bars" color="black" size="xs" />
        </Flex>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));
