import React, { useState, useEffect } from 'react';
import { Box, TextInput, Select, Button, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import kbApi from 'services/kb';
import kbSlice from 'slices/kb';
import { useAppSelector } from 'hooks';
import { Version } from 'types/kb';

const COLLECTIONS = [
    'conditions',
    'papers',
    'organisms',
    'symptoms',
    'sensitivities',
    'diets',
    'treatments',
    'metabolisms',
    'lifestyles',
];

const VERSIONS = [
    Version.v1,
    Version.v2,
    Version.prerelease
];

export default function Search() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();
    const [collection, setCollection] = useState(getCollection() || 'conditions');
    const [query, setQuery] = useState('');
    const [prevPath, setPrevPath] = useState('');
    const filters = useAppSelector((state) => state.kb.filters[collection]);
    const loading = useAppSelector((state) => state.kb.loading);
    const page = useAppSelector((state) => state.kb.page);
    const results = useAppSelector((state) => state.kb.results);
    const sort = useAppSelector((state) => state.kb.sort);
    const appVersion = useAppSelector((state) => state.kb.version);
    const [version, setVersion] = useState(appVersion);


    const [searchPapers] = kbApi.endpoints.searchPapers.useMutation();
    const [searchConditions] = kbApi.endpoints.searchConditions.useMutation();
    const [searchSymptoms] = kbApi.endpoints.searchSymptoms.useMutation();
    const [searchSensitivities] =
        kbApi.endpoints.searchSensitivities.useMutation();
    const [searchDiets] = kbApi.endpoints.searchDiets.useMutation();
    const [searchTreatments] = kbApi.endpoints.searchTreatments.useMutation();
    const [searchMetabolisms] = kbApi.endpoints.searchMetabolisms.useMutation();
    const [searchOrganisms] = kbApi.endpoints.searchOrganisms.useMutation();
    const [searchLifestyles] = kbApi.endpoints.searchLifestyles.useMutation();

    console.log(`kbVersion: ${appVersion}`);

    const search = {
        papers: searchPapers,
        conditions: searchConditions,
        symptoms: searchSymptoms,
        sensitivities: searchSensitivities,
        diets: searchDiets,
        treatments: searchTreatments,
        metabolisms: searchMetabolisms,
        organisms: searchOrganisms,
        lifestyles: searchLifestyles,
    } as any;

    if (location.pathname !== prevPath) {
        resetResults();
        setCollection(getCollection() || 'conditions');
        setPrevPath(location.pathname);
    }

    function resetResults() {
        dispatch(kbSlice.actions.setResults([]));
        dispatch(kbSlice.actions.setPage(1));
        dispatch(kbSlice.actions.setTotal(0));
    }

    function getCollection() {
        const name = location.pathname.split('/').pop();
        if (name === 'kb') {
            return 'conditions';
        } else {
            return name;
        }
    }

    function handleChange(e: any) {
        setQuery(e.currentTarget.value);
    }

    function handleCollectionChange(collection: string) {
        setCollection(collection);
        navigate(`/kb/${collection}`);
    }

    function handleVersionChange(version: Version) {
        setVersion(version);
        dispatch(kbSlice.actions.setVersion(version));
    }

    async function handleSearch(clear = false) {
        dispatch(kbSlice.actions.setLoading(true));
        console.log({ collection, query, filters, page, sort, version });
        const { data } = await search[collection]({
            query,
            filters,
            page,
            sort,
            version
        });

        if (clear || data.items.length === 0) {
            dispatch(kbSlice.actions.setResults(data.items));
        } else {
            dispatch(kbSlice.actions.setResults([...results, ...data.items]));
        }

        dispatch(kbSlice.actions.setTotal(data.total));
        dispatch(kbSlice.actions.setLoading(false));
    }

    useEffect(() => {
        resetResults();
        handleSearch(true);
    }, [filters, sort]);
    useEffect(() => {
        handleSearch(false);
    }, [page]);

    function handleSearchClick(event: any) {
        if (event.key === 'Enter' || event.type === 'click') {
            resetResults();
            handleSearch(true);
        }
    }

    return (
        <Box className={classes.container}>
            <FontAwesomeIcon className={classes.icon} icon={faSearch} />
            <TextInput
                placeholder="Search the knowledge base..."
                className={classes.input}
                value={query}
                onChange={handleChange}
                onKeyDown={handleSearchClick}
            />
            <Select
                size="lg"
                className={classes.select}
                value={version}
                onChange={handleVersionChange}
                data={VERSIONS}
            />
            <Select
                size="lg"
                className={classes.select}
                value={collection}
                onChange={handleCollectionChange}
                data={COLLECTIONS}
            />
            <Button
                className={classes.button}
                variant="light"
                size="lg"
                onClick={handleSearchClick}
                loading={loading}
            >
                Search
            </Button>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background: 'white',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
    },
    icon: {
        marginLeft: theme.spacing.lg,
        color: theme.colors.gray[5],
    },
    input: {
        width: '100%',
        marginLeft: theme.spacing.md,
        marginRight: theme.spacing.xl,
        paddingTop: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        input: {
            padding: 0,
            fontSize: theme.fontSizes.xl,
            border: 'none',
            outline: 'none',
        },
    },
    button: {
        height: '60px',
        minWidth: '150px',
        margin: theme.spacing.sm,
    },
    select: {
        input: {
            height: '60px',
            width: '200px',
            paddingLeft: theme.spacing.md,
            textTransform: 'capitalize',
            color: theme.colors.gray[6],
            fontWeight: 700,
            border: 'none',
            background: theme.colors.gray[1],
        },
        item: {
            textTransform: 'capitalize',
        },
        margin: theme.spacing.sm,
        marginRight: 0,
    },
}));
