import React, { useEffect, useState } from 'react';
import {
  Affix,
  Button,
  Flex,
  Grid,
  Input,
  Loader,
  Modal,
  ScrollArea,
  Table,
  Text,
  createStyles,
  rem,
} from '@mantine/core';
import userApi, { IPickerUser } from 'services/userV2';
import Loading from 'pages/Loading';
import { useDebouncedState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

interface Props {
  opened: boolean;
  close: () => void;
  addUserCallback: (user: IPickerUser) => void;
  removeUserCallback: (user: IPickerUser) => void;
  defaultValues?: IPickerUser[];
  limit?: number;
}

export default function UserSearchPickerModal({
  opened,
  close,
  addUserCallback,
  removeUserCallback,
  defaultValues,
  limit,
}: Props) {
  const [currentUsers, setCurrentUsers] = useState(defaultValues || []);
  const [query, setQuery] = useDebouncedState('', 250);
  const [searchUsers, { data: users, isLoading: searchUsersLoading, isFetching }] =
    userApi.endpoints.searchUsers.useLazyQuery();

  useEffect(() => {
    setCurrentUsers(defaultValues || []);
  }, [defaultValues]);

  useEffect(() => {
    async function fetchData() {
      await searchUsers(query);
    }

    if (opened) {
      fetchData();
    }
  }, [query, searchUsers, opened]);

  if (searchUsersLoading) {
    return <Loading />;
  }

  function handleAddUserClick(user: IPickerUser) {
    if (limit && currentUsers.length >= limit) {
      notifications.show({
        title: 'Error',
        message: `Maximum of ${limit} user(s) can be added`,
        color: 'red',
      });
      return;
    }

    if (!currentUsers.includes(user)) {
      addUserCallback(user);
    }
  }

  function handleRemoveUserClick(user: IPickerUser) {
    if (currentUsers.includes(user)) {
      removeUserCallback(user);
    }
  }

  function getName(firstName: string | undefined, lastName: string | undefined) {
    if (!firstName && !lastName) {
      return 'N/A';
    } else {
      return `${firstName} ${lastName}`;
    }
  }

  function handleClose() {
    setCurrentUsers([]);
    close();
  }

  function handleAddUsersClick() {
    setCurrentUsers([]);
    close();
  }

  return (
    <Modal opened={opened} onClose={handleClose} size="100%" scrollAreaComponent={ScrollArea.Autosize}>
      <Grid>
        <Grid.Col span={6}>
          <Flex direction="column">
            <Text fw="bold">Search Users</Text>
            <Input
              spellCheck={false}
              rightSection={isFetching ? <Loader size="xs" color="gray" variant="bars" /> : null}
              placeholder="Search for user by name or email..."
              defaultValue={query}
              onChange={(event) => setQuery(event.currentTarget.value)}
              style={{ marginBottom: '16px' }}
            />

            <Table striped withBorder>
              <tbody>
                {users &&
                  users.items.length > 0 &&
                  users.items
                    .filter((user: IPickerUser) => !currentUsers.find((u: IPickerUser) => u.email === user.email))
                    .map((user: IPickerUser, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            <Text ml="md">{getName(user.firstName, user.lastName)}</Text>
                          </td>
                          <td>{user.email}</td>
                          <td>
                            <Flex justify="end">
                              <Button
                                mr="xs"
                                color="green"
                                variant="light"
                                onClick={() => handleAddUserClick(user)}
                                disabled={limit != undefined && currentUsers.length >= limit}
                              >
                                +
                              </Button>
                            </Flex>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Flex direction="column" pr="xs">
            <Text fw="bold" mb="lg">
              Added Users
            </Text>
            <Table striped withBorder w="100%">
              <tbody>
                {currentUsers &&
                  currentUsers.map((user: IPickerUser, i: number) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Text ml="md">{getName(user.firstName, user.lastName)}</Text>
                        </td>
                        <td>{user.email}</td>
                        <td>
                          <Flex justify="end">
                            <Button mr="xs" color="red" variant="light" onClick={() => handleRemoveUserClick(user)}>
                              X
                            </Button>
                          </Flex>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Flex>
        </Grid.Col>
      </Grid>
      <Affix ml="md" position={{ bottom: rem(25), right: rem(20) }} withinPortal={false}>
        <Button onClick={handleAddUsersClick} variant="light">
          Close
        </Button>
      </Affix>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // alignSelf: 'flex-start',
    padding: theme.spacing.sm,
    // marginBottom: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '4px',
    background: 'white',
    '&:hover': {
      cursor: 'pointer',
      background: theme.colors.blue[0],
    },
    height: '100%',
  },
  icon: {
    marginRight: theme.spacing.sm,
    color: theme.colors.gray[6],
    fontSize: '10px',
  },
}));
