import React from 'react';
import { Flex, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import OrganismActions from 'components/KB/OrganismActions';
import OrganismFilter from 'components/KB/OrganismFilter';
import OrganismResults from 'components/KB/OrganismResults';

export default function Organisms() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <Flex direction="column">
                <OrganismActions />
                <OrganismFilter />
            </Flex>
            <OrganismResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
