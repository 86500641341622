import { Box, Title, Text, createStyles, Flex, Button, Modal, Grid, ScrollArea } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../pages/Loading';
import Forbidden from '../../pages/Forbidden';
import BackButton from 'components/BackButton';
import { useDisclosure } from '@mantine/hooks';
import sampleApi from 'services/sample';

export default function SampleDetail() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { sampleId } = useParams();
  const [debugOpened, { close: debugClose, open: debugOpen }] = useDisclosure(false);

  if (!sampleId) {
    return <Text>Sample ID not provided</Text>;
  }

  const { data, isLoading, error } = sampleApi.endpoints.getSample.useQuery({ id: parseInt(sampleId) });

  if (!sampleId) {
    navigate('/samples');
  }

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  function handleEditClick() {
    navigate(`/samples/${sampleId}/edit`);
  }

  async function deleteSampleCallback() {
    close();
    navigate('/samples');
  }

  return (
    <Box className={classes.container}>
      <Grid>
        <Grid.Col span={2} mb="md">
          <BackButton to={'/samples'} label="Back" />
        </Grid.Col>
        <Grid.Col span={3} offset={7} mb="md">
        <Flex gap='md'>
            <Button onClick={debugOpen} variant="outline">
                Debug
            </Button>
            <Button onClick={handleEditClick} variant="outline">
                Edit
            </Button>
            <Button variant="filled" color="red">
                Delete
            </Button>
            {/* <DeleteProviderModal opened={opened} close={close} providerId={providerId} callback={deleteSampleCallback} /> */}
        </Flex>
        </Grid.Col>
      </Grid>

      <Flex direction="column" align="flex-start">
        <Title order={3}>{data.barcode}</Title>
        <Box>
          <Modal opened={debugOpened} onClose={debugClose} size="100%">
            <Box>
              <Title order={3}>Debug Sample {data.barcode}</Title>
              <pre>{JSON.stringify(data, null, 3)}</pre>
            </Box>
          </Modal>
        </Box>
      </Flex>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    margin: theme.spacing.lg,
  },
  box: {
    width: 'fit-content',
    padding: theme.spacing.md,
    marginRight: theme.spacing.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '4px',
  },
  header: {
    marginBottom: theme.spacing.lg,
  },
  row: {
    cursor: 'pointer',
  },
}));
