export enum Version {
    v1 = "v1",
    v2 = "v2",
    prerelease = "prerelease"
}

export const KBVersions = [
    Version.v1,
    Version.v2,
    Version.prerelease
];

export function getVersion(version: string) : Version {
    switch (version) {
        case "Version.v1":
            return Version.v1;
        case Version.v2:
            return Version.v2;
        case Version.prerelease:
            return Version.prerelease;
        default:
            throw new Error(`${version} not supported`);
    }
}
