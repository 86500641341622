import React, { useState } from 'react';
import { Box, Flex, Select, Text, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import Column from 'components/Column';
import Row from 'components/Row';
import Label from 'components/Label';
import userApi from 'services/user';
import PaperResultsItem from './PaperResultsItem';
import { useAppSelector } from 'hooks';
import kbSlice from 'slices/kb';
import { SORT_OPTIONS } from 'util/constants';

const PAPER_SORT_OPTIONS = SORT_OPTIONS.concat(['Score', 'Published Date']);

export default function PaperResults() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classes } = useStyles();
    // const [sort, setSort] = useState(SORT_OPTIONS[0]);
    const [cursor, setCursor] = useState(20);
    const PAGE_LENGTH = 20;
    const results = useAppSelector((state) => state.kb.results);
    const loading = useAppSelector((state) => state.kb.loading);
    const page = useAppSelector((state) => state.kb.page);
    const total = useAppSelector((state) => state.kb.total);
    const sort = useAppSelector((state) => state.kb.sort);

    function loadPapers() {
        dispatch(kbSlice.actions.setPage(page + 1));
        setCursor(cursor + PAGE_LENGTH);
    }

    function handleSortChange(option: string) {
        dispatch(kbSlice.actions.setSort(option));
    }

    const papers = results.slice(0, cursor);

    return (
        <Column className={classes.container}>
            <Row className={classes.header}>
                <Text size="lg" weight={600}>
                    {total.toLocaleString()} papers found
                </Text>
                <Select
                    className={classes.sort}
                    size="sm"
                    placeholder="Sort by..."
                    value={sort}
                    data={PAPER_SORT_OPTIONS}
                    onChange={handleSortChange}
                />
            </Row>
            <Box id="results" className={classes.results}>
                {total === 0 && loading && <Loader />}
                <InfiniteScroll
                    dataLength={papers.length}
                    next={loadPapers}
                    hasMore={papers.length < total}
                    loader={<Loader />}
                    scrollableTarget="results"
                >
                    {papers.map((paper: any, idx: number) => (
                        <PaperResultsItem
                            key={`${paper._id}-${idx}`}
                            {...paper}
                        />
                    ))}
                </InfiniteScroll>
            </Box>
        </Column>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        height: 'fit-content',
        marginBottom: theme.spacing.xl,
        background: 'white',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.gray[3]}`,
    },
    header: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    sort: {
        input: {
            width: '150px',
            fontWeight: 600,
            color: theme.colors.gray[6],
            border: 'none',
            '&:hover': {
                background: theme.colors.gray[1],
            },
        },
    },
    results: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '1024px',
        overflow: 'auto',
    },
}));
