import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthHeader } from './token';

const API_URL = process.env.NODE_ENV === 'production' ? window.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL;

export enum TagTypes {
  user = 'user',
  report = 'report',
  provider = 'provider',
  sample = 'sample',
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: getAuthHeader,
  }),
  tagTypes: [TagTypes.user, TagTypes.report, TagTypes.provider, TagTypes.sample],
  endpoints: () => ({}),
});
