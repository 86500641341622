import { Box, Container, Title, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';

export default function Dashboard() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Container className={classes.container}>
            <Row className={classes.header}>
                <Column className={classes.box}>
                    <Label>Angry Instagram Comments</Label>
                    <Title>{155}</Title>
                </Column>
                <Column className={classes.box}>
                    <Label>Shrimps</Label>
                    <Title>{462}</Title>
                </Column>
                <Column className={classes.box}>
                    <Label>Forgotten Passwords</Label>
                    <Title>{4122}</Title>
                </Column>
            </Row>
        </Container>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        margin: theme.spacing.lg,
    },
    box: {
        width: 'fit-content',
        padding: theme.spacing.md,
        marginRight: theme.spacing.lg,
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: '4px',
    },
    header: {
        marginBottom: theme.spacing.lg,
    },
    row: {
        cursor: 'pointer',
    },
}));
