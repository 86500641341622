import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Routes, Route } from 'react-router-dom';

import Loading from 'pages/Loading';
import Home from 'pages/Home';
import Dashboard from 'pages/Dashboard';
import Papers from 'pages/Papers';
import Paper from 'pages/Paper';
import Conditions from 'pages/Conditions';
import Condition from 'pages/Condition';
import Symptoms from 'pages/Symptoms';
import Symptom from 'pages/Symptom';
import Diets from 'pages/Diets';
import Diet from 'pages/Diet';
import Treatments from 'pages/Treatments';
import Treatment from 'pages/Treatment';
import Metabolisms from 'pages/Metabolisms';
import Metabolism from 'pages/Metabolism';
import Organisms from 'pages/Organisms';
import Organism from 'pages/Organism';
import Sensitivities from 'pages/Sensitivities';
import Sensitivity from 'pages/Sensitivity';
import User from 'pages/User';
import KB from 'pages/KB';
import ProtectedRoute from 'components/ProtectedRoute';
import Callback from 'components/Callback';
import { setToken as setAccessToken } from 'services/token';
import KBLookup from 'pages/KBLookup';
import Lifestyles from 'pages/Lifestyles';
import Lifestyle from 'pages/Lifestyle';
import Providers from 'pages/Providers';
import ProviderDetail from 'components/Providers/ProviderDetail';
import AllProviders from 'components/Providers/AllProviders';
import EditProviderContainer from 'components/Providers/EditProviderContainer';
import Dev from 'pages/Dev';
import DigestionTest from 'components/Dev/DigestionTest';
import Samples from 'pages/Samples';
import AllSamples from 'components/Samples/AllSamples';
import SampleDetail from 'components/Samples/SampleDetail';
import EditSampleContainer from 'components/Samples/EditSampleContainer';
import Users from 'pages/Users';

export default function RoutesComponent() {
    const [token, setToken] = useState('');
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchToken = async () => {
            const accessToken = await getAccessTokenSilently();
            setAccessToken(accessToken);
            setToken(accessToken);
        };

        if (isAuthenticated) {
            fetchToken();
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    if (isLoading || (!token && isAuthenticated)) {
        return <Loading />;
    }

    return (
        <Routes>
            {/* Base */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route element={<ProtectedRoute component={Home} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/loading" element={<Loading />} />
                <Route path="/users/:userId" element={<User />} />

                <Route path="/dev" element={<Dev />}>
                    <Route path="/dev/digestion-test" element={<DigestionTest />} />
                </Route>

                <Route path="/kb/lookup" element={<KBLookup />} />
                <Route path="/kb" element={<KB />}>
                    <Route path="/kb/:version?/papers" element={<Papers />} />
                    <Route path="/kb/:version?/conditions" element={<Conditions />} />
                    <Route path="/kb/:version?/symptoms" element={<Symptoms />} />
                    <Route path="/kb/:version?/sensitivities" element={<Sensitivities />} />
                    <Route path="/kb/:version?/diets" element={<Diets />} />
                    <Route path="/kb/:version?/treatments" element={<Treatments />} />
                    <Route path="/kb/:version?/metabolisms" element={<Metabolisms />} />
                    <Route path="/kb/:version?/organisms" element={<Organisms />} />
                    <Route path="/kb/:version?/lifestyles" element={<Lifestyles />} />
                </Route>

                <Route path="/kb/:version?/papers/:paperId" element={<Paper />} />
                <Route path="/kb/:version?/conditions/:conditionId" element={<Condition />} />
                <Route path="/kb/:version?/symptoms/:symptomId" element={<Symptom />} />
                <Route path="/kb/:version?/sensitivities/:sensitivityId" element={<Sensitivity />} />
                <Route path="/kb/:version?/diets/:dietId" element={<Diet />} />
                <Route path="/kb/:version?/treatments/:treatmentId" element={<Treatment />} />
                <Route path="/kb/:version?/metabolisms/:metabolismId" element={<Metabolism />} />
                <Route path="/kb/:version?/organisms/:organismId" element={<Organism />} />
                <Route path="/kb/:version?/lifestyles/:lifestyleId" element={<Lifestyle />} />

                <Route element={<Providers/>}>
                    <Route path="/providers" element={<AllProviders />} />
                    <Route path="/providers/:providerId" element={<ProviderDetail />} />
                    <Route path="/providers/:providerId/edit" element={<EditProviderContainer />} />
                </Route>

                <Route element={<Samples/>}>
                    <Route path="/samples" element={<AllSamples />} />
                    <Route path="/samples/:sampleId" element={<SampleDetail />} />
                    <Route path="/samples/:sampleId/edit" element={<EditSampleContainer />} />
                </Route>
            </Route>

            <Route path="/callback" element={<Callback />} />
            <Route path="/loading" element={<Loading />} />

        </Routes>
    );
}
