import { Box, createStyles, Flex } from '@mantine/core';
import { useParams } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Body from 'components/Body';
import BackButton from 'components/BackButton';
import LifestyleForm from 'components/KB/LifestyleForm';
import kbApi from 'services/kb';
import Forbidden from './Forbidden';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import kbSlice from 'slices/kb';

export default function Lifestyle() {
  const dispatch = useDispatch();
  const { lifestyleId, version } = useParams();
  const { classes } = useStyles();
  const stateVersion = useAppSelector((state) => state.kb.version);
  
  if (version !== undefined) {
    if (version !== stateVersion) {
        dispatch(kbSlice.actions.setVersion(version));
    }
  }
  
  const { data, isLoading, error, refetch } = kbApi.endpoints.getLifestyle.useQuery(
    {
      id: lifestyleId,
      version: version
    }
  );

  if (error && ('data' in error)) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Box>
      <Body>
        <Box className={classes.container}>
          <Flex mb="md" justify="space-between" align="flex-end">
            <BackButton to={-1} label="Back" />
          </Flex>
          <Row className={classes.body}>
            <LifestyleForm refetch={refetch} {...data} />
          </Row>
        </Box>
      </Body>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing.lg,
  },
  body: {},
}));
