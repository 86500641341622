import { emptySplitApi } from './emptyApi';
import { DigestionGuidanceResultTest } from 'types/dev/digestionTest';

const devApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDigestionGuidanceTest: builder.query<DigestionGuidanceResultTest[], void>({
            query: () => ({
                url: `/api/dev/digestion-test`,
            }),
        }),
    }),
});

export default devApi;
