import React from 'react';
import { Box, Title, Table, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import PaperFilter from 'components/KB/PaperFilter';
import PaperResults from 'components/KB/PaperResults';

export default function Papers() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <PaperFilter />
            <PaperResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
