import React from 'react';
import { Box, Title, Table, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Loading from './Loading';
import Navbar from 'components/Navbar';
import Body from 'components/Body';
import Search from 'components/Search';
import userApi from 'services/user';
import kbApi from 'services/kb';
import Forbidden from './Forbidden';
import { useAppSelector } from 'hooks';

export default function KB() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    // Add /auth route to portal controller in API to check permissions
    const version = useAppSelector((state) => state.kb.version);
    const { error, isLoading } = kbApi.endpoints.getCondition.useQuery({ version });

    if (error && ('data' in error)) {
        if (error.status === 403) {
            return <Forbidden />;
        }
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box>
            <Body>
                <Search />
                <Outlet />
            </Body>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({}));
