import { Button, Flex, TextInput, Text, createStyles, Textarea, Code } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DescriptionList } from 'types/kb/prerelease';

interface FormValues {
  bullet_point_description: [{ header: string; descriptions: string[] }];
}

export function BulletPointDescriptionsForm({ form }: { form: UseFormReturnType<FormValues> }) {
  const { classes } = useStyles();

  const fields = form.values.bullet_point_description?.map((descriptionList: DescriptionList, headerIndex: number) => {
    return (
      <div key={headerIndex}>
        <Flex align="center" justify="flex-start" gap="md">
          <TextInput
            className={classes.description}
            label="Header"
            {...form.getInputProps(`bullet_point_description.${headerIndex}.header`)}
          />
          <Button type='button' mt="md" color='red' onClick={(event) => form.removeListItem(`bullet_point_description`, headerIndex)}>
            X
          </Button>
        </Flex>

        <Flex direction="column" gap="lg" ml="xl" mt="md">
          {descriptionList.descriptions.map((description: string, lineIndex: number) => {
            return (
              <Flex align="center" justify="flex-start" key={`${headerIndex}-${lineIndex}`} gap="md">
                <Text size="xl">•</Text>
                <Textarea
                  minRows={3}
                  placeholder="Enter description line item..."
                  className={classes.description}
                  {...form.getInputProps(`bullet_point_description.${headerIndex}.descriptions.${lineIndex}`)}
                />
                <Button type='button' color='red' onClick={(event) => form.removeListItem(`bullet_point_description.${headerIndex}.descriptions`, lineIndex)}>X</Button>
              </Flex>
            );
          })}
          <Button type='button' onClick={(event) => form.insertListItem(`bullet_point_description.${headerIndex}.descriptions`, '')}>+ Description</Button>
        </Flex>
      </div>
    );
  });

  return (
    <Flex mb="lg" direction="column" gap="lg">
      {fields}  
      <Button type='button' onClick={() => form.insertListItem(`bullet_point_description`, { header: '', descriptions: ['']})}>+ Header</Button>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    minWidth: '550px',
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
