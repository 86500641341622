import { createSlice } from '@reduxjs/toolkit';

export interface HomeState {
    stats: any;
}

const initialState: HomeState = {
    stats: {
        item: {
            type: '',
            value: '',
        },
    },
};

const homeSlice = createSlice({
    name: 'kb',
    initialState,
    reducers: {
        setStatsItem: (state, action) => {
            state.stats.item = action.payload;
        },
        setStatsItemType: (state, action) => {
            state.stats.item.type = action.payload;
            state.stats.item.value = '';
        },
        setStatsItemValue: (state, action) => {
            state.stats.item.value = action.payload;
        },
    },
});

export default homeSlice;
