import { Box, Flex, Title, Text, createStyles, Tooltip, Badge } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';

import userApi from 'services/user';
import Loading from './Loading';
import Label from 'components/Label';
import Forbidden from './Forbidden';
import { UserSample } from 'types/allUsers';
import { getColorBySampleStatus } from 'util/sampleHelpers';

export default function User() {
  const { classes } = useStyles();
  const { userId } = useParams();
  const { data, isLoading, error } = userApi.endpoints.getUser.useQuery({ id: userId });

  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  let name: string = '';
  if (data.firstName && data.lastName) {
    name = `${data.firstName} ${data.lastName}`;
  } else {
    name = `${data.email}`;
  }

  return (
    <Box className={classes.container}>
      <Flex wrap="wrap" className={classes.box}>
        <Flex direction="column" mr="lg">
          <Flex direction="column" gap="md" className={classes.item}>
            <Label>Name</Label>
            <Title>{name}</Title>
          </Flex>
          <Flex direction="column" className={classes.item}>
            <Label>Email</Label>
            <Title>{data.email}</Title>
          </Flex>
        </Flex>
        <Flex direction="column">
          <Flex direction="column" className={classes.item}>
            <Label>ID</Label>
            <Text>{data.id.toString()}</Text>
          </Flex>
          <Flex direction="column" className={classes.item}>
            <Label>Auth0 ID</Label>
            <Text>{data.auth0Id}</Text>
          </Flex>
          <Flex direction="column" className={classes.item}>
            <Label>Barcodes</Label>
            <Flex gap="xs" wrap="wrap">
              {data.samples.map((kit: UserSample, i: number) => {
                return (
                  <Tooltip key={`kit-badge-${i}`} label={kit.sampleStatus}>
                    <Link to={`/samples/${kit.id}`}>
                      <Badge color={getColorBySampleStatus(kit.sampleStatus)} size="md" variant="dot" radius="xs">
                        {kit.barcode}
                      </Badge>
                    </Link>
                  </Tooltip>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    margin: theme.spacing.lg,
  },
  box: {
    width: 'fit-content',
    padding: theme.spacing.md,
    marginRight: theme.spacing.lg,
    border: `1px solid ${theme.colors.gray[5]}`,
    borderRadius: '4px',
    marginBottom: theme.spacing.md,
  },
  header: {
    marginBottom: theme.spacing.lg,
  },
  Flex: {
    cursor: 'pointer',
  },
  item: {
    marginBottom: theme.spacing.lg,
  },
}));
