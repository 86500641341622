import React, { useState } from 'react';
import { Flex, Tabs, createStyles, Select } from '@mantine/core';

import { KBVersions, Version } from 'types/kb';
import Loading from './Loading';
import kbApi from 'services/kb';
import KBAccordionTable from 'components/KBAccordionTable';
import Forbidden from './Forbidden';


export default function KBLookup() {
    const { classes, cx } = useStyles();
    const [version, setVersion] = useState(Version.prerelease);
    const { data, isLoading, error } = kbApi.endpoints.getDisplayed.useQuery({ version });

    if (error && ('data' in error)) {
        if (error.status === 403) {
            return <Forbidden />;
        }
    }

    if (isLoading || !data) {
        return <Loading />;
    }

    function handleVersionChange(version: Version) {
        setVersion(version);
    }

    return (
        <Flex direction='column'>
            <Flex justify='flex-end'>
                <Select
                    size="sm"
                    className={classes.select}
                    value={version}
                    onChange={handleVersionChange}
                    data={KBVersions}
                />
            </Flex>
            <Tabs p="lg" radius="md" className={classes.tabsHeader} defaultValue="conditions">
                <Tabs.List>
                    <Tabs.Tab value="conditions">Conditions</Tabs.Tab>
                    <Tabs.Tab value="metabolisms">Metabolisms</Tabs.Tab>
                    <Tabs.Tab value="symptoms">Symptoms</Tabs.Tab>
                    <Tabs.Tab value="diets">Diets</Tabs.Tab>
                    <Tabs.Tab value="treatments">Treatments</Tabs.Tab>
                    {data.lifestyles && (
                        <Tabs.Tab value="lifestyles">Lifestyles</Tabs.Tab>
                    )}
                </Tabs.List>

                <Tabs.Panel value="conditions" pt="xs">
                    <KBAccordionTable findings={data.conditions} />
                </Tabs.Panel>

                <Tabs.Panel value="metabolisms" pt="xs">
                    <KBAccordionTable findings={data.metabolisms} />
                </Tabs.Panel>

                <Tabs.Panel value="treatments" pt="xs">
                    <KBAccordionTable findings={data.treatments} />
                </Tabs.Panel>

                <Tabs.Panel value="diets" pt="xs">
                    <KBAccordionTable findings={data.diets} />
                </Tabs.Panel>

                <Tabs.Panel value="symptoms" pt="xs">
                    <KBAccordionTable findings={data.symptoms} />
                </Tabs.Panel>

                {data.lifestyles && (
                    <Tabs.Panel value="lifestyles" pt="xs">
                        <KBAccordionTable findings={data.lifestyles} />
                    </Tabs.Panel>
                )}
            </Tabs>
        </Flex>
    );
}

const useStyles = createStyles((theme) => ({
    tabsHeader: {
        position: 'sticky'
    },
    select: {
        input: {
            paddingLeft: theme.spacing.md,
            textTransform: 'capitalize',
            color: theme.colors.blue[6],
            fontWeight: 700,
            background: theme.colors.blue[1],
        },
        item: {
            textTransform: 'capitalize',
        },
        margin: theme.spacing.sm,
        marginRight: 0,
    },
}));
