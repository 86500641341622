import React from 'react';
import { Flex, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import SensitivityActions from 'components/KB/SensitivityActions';
import SensitivityFilter from 'components/KB/SensitivityFilter';
import SensitivityResults from 'components/KB/SensitivityResults';

export default function Sensitivities() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <Flex direction="column">
                <SensitivityActions />
                <SensitivityFilter />
            </Flex>
            <SensitivityResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
