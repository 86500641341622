import React from 'react';
import { Flex, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import Loading from './Loading';
import Row from 'components/Row';
import Column from 'components/Column';
import SymptomActions from 'components/KB/SymptomActions';
import SymptomFilter from 'components/KB/SymptomFilter';
import SymptomResults from 'components/KB/SymptomResults';

export default function Symptoms() {
    const navigate = useNavigate();
    const { classes } = useStyles();

    return (
        <Row className={classes.container}>
            <Flex direction="column">
                <SymptomActions />
                <SymptomFilter />
            </Flex>
            <SymptomResults />
        </Row>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: theme.spacing.lg,
    },
}));
