import { Box, Flex, Title, Text} from '@mantine/core';
import devApi from 'services/dev';
import Markdown from 'react-markdown';
import { DigestionGuidanceResultTest } from 'types/dev/digestionTest';
import Forbidden from 'pages/Forbidden';

export default function DigestionTest() {
    const { data, isLoading, error} = devApi.endpoints.getDigestionGuidanceTest.useQuery();

    if (error && ('data' in error)) {
        if (error.status === 403) {
            return <Forbidden />;
        }
    }

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box>
            {data && data.map((guidance: DigestionGuidanceResultTest, i: number) => (
                <Flex mb='lg' direction='column' key={i}>
                    <Title order={3}>{guidance.scenario}</Title>

                    {guidance.result.customGuidance.map((guidance, k: number) => (
                        <Markdown key={k}>{guidance}</Markdown>
                    ))}

                    <Box>Should Hide: {guidance.result.shouldHideAction ? 'True' : 'False'}</Box>
                </Flex>
            ))}
        </Box>
    );
}
