import { useForm, yupResolver } from '@mantine/form';
import { Button, Flex, Modal, TextInput, createStyles } from '@mantine/core';
import * as Yup from 'yup';
import { notifications } from '@mantine/notifications';
import sampleApi from 'services/sample';

interface CreateSampleModalProps {
  opened: boolean;
  close: () => void;
  callback: () => void;
}

const schema = Yup.object().shape({
  barcode: Yup.string().required(
    "Barcode is required"
  )
});

export default function CreateSampleModal({ opened, callback, close }: CreateSampleModalProps) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      barcode: '',
    },
    validate: yupResolver(schema),
  });

  const [createSample, error] = sampleApi.endpoints.createSample.useMutation();

  async function handleSubmit(values: any, event: any) {
    event.preventDefault();
    await createSample({
      barcode: values.barcode,
    });

    if (error && 'data' in error) {
      notifications.show({
        title: 'Error',
        message: 'There was an error creating the sample',
        color: 'red',
      });
    } else {
      notifications.show({
        title: 'Success',
        message: `Sample created for ${values.barcode}`,
        color: 'green',
      });
    }

    await callback();
  }

  return (
    <Modal opened={opened} onClose={close} title="New Sample" centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>  
          <Flex justify='center' gap='lg'>
                <TextInput
                  className={classes.fieldCol}
                  label="Barcode"
                  placeholder="Enter the barcode..."
                  {...form.getInputProps('barcode')}
                />
            </Flex>
            <Button mt='lg' variant='light' fullWidth type='submit'>
              Create Sample
            </Button>
        </form>
      </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  fieldCol: {
    width: '75%',
  },
}));