import { Affix, createStyles, Button, Checkbox, Flex, Text, rem, Loader } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import moment from 'moment';

import Label from 'components/Label';
import { IsDisplayForm } from 'types/forms';

export interface MetaHeaderProps {
  collectionName: string;
  createdAt: string;
  updatedAt: string;
  form: UseFormReturnType<IsDisplayForm>;
  isDisplay?: boolean;
  saveCallback: () => void;
  loading?: boolean;
}

export default function MetaHeader({
  createdAt,
  updatedAt,
  collectionName,
  form,
  isDisplay = true,
  saveCallback,
  loading,
}: MetaHeaderProps) {
  const { classes } = useStyles();

  return (
    <Affix ml="md" position={{ top: rem(65), right: rem(20) }}>
      <Flex className={classes.pane} gap="md" align="center" direction="row">
        {loading && <Loader />}
        <Flex ml="sm" direction="column">
          <Label>Created at</Label>
          <Text>{moment(createdAt).format('LLL')}</Text>
        </Flex>

        <Flex direction="column">
          <Label>Edited at</Label>
          <Text>{moment(updatedAt).format('LLL')}</Text>
        </Flex>

        {isDisplay && (
          <>
            <Checkbox label="Should Recommend" {...form.getInputProps('should_recommend', { type: 'checkbox' })} />
            <Checkbox label="Display" {...form.getInputProps('is_display', { type: 'checkbox' })} />
          </>
        )}

        <Button variant="light" type="submit" onClick={saveCallback}>
          Save {collectionName}
        </Button>
      </Flex>
    </Affix>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.xl,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  pane: {
    minWidth: '350px',
    padding: theme.spacing.sm,
    background: 'white',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  form: {},
  section: {
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  fieldCol: {
    width: '50%',
  },
  description: {
    width: '100%',
  },
  multiselect: {
    width: '100%',
  },
}));
