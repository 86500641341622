
import { CreateSampleRequest, IPickerSampleIntake, IUserSample } from 'types/sample/sampleApiTypes';
import { TagTypes, emptySplitApi } from './emptyApi';

const BASE_PATH = '/api/portal/sample';

const sampleApi = emptySplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getSample: builder.query<IUserSample, { id: number | undefined}>({
            query: ({id}) => ({
                url: `${BASE_PATH}/${id}`,
            }),
            providesTags: (result, error, arg) => (result?.id ? [{ type: TagTypes.sample, id: result.id }] : []),
        }),
        getAllSamples: builder.query<IUserSample[], undefined> ({
            query: () => ({
                url: `${BASE_PATH}/all`,
            }),
            providesTags: (result) => (result ? [...result.map((sample) => ({ type: TagTypes.sample, id: sample.id }))] : []),
        }),
        createSample: builder.mutation<IUserSample, CreateSampleRequest>({
            query: ({ barcode }) => ({
                url: `${BASE_PATH}`,
                method: 'POST',
                body: { barcode },
            }),
            invalidatesTags: [{ type: TagTypes.sample }],
        }),
        updateSample: builder.mutation<IUserSample, Partial<IUserSample>>({
            query: (sample) => ({
                url: `${BASE_PATH}/${sample.id}`,
                method: 'POST',
                body: sample,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.sample, id: arg.id }]),
        }),
        assignUser: builder.mutation<IUserSample, {sampleId: number, userId: number}>({
            query: (sample) => ({
                url: `${BASE_PATH}/${sample.sampleId}/user/${sample.userId}/assign`,
                method: 'POST',
                body: sample,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.sample, id: arg.sampleId }]),
        }),
        removeUser: builder.mutation<IUserSample, {sampleId: number, userId: number}>({
            query: (sample) => ({
                url: `${BASE_PATH}/${sample.sampleId}/user/${sample.userId}/remove`,
                method: 'POST',
                body: sample,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.sample, id: arg.sampleId }]),
        }),
        assignIntake: builder.mutation<IUserSample, {sampleId: number, intakeId: number}>({
            query: (sample) => ({
                url: `${BASE_PATH}/${sample.sampleId}/intake/${sample.intakeId}/assign`,
                method: 'POST',
                body: sample,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.sample, id: arg.sampleId }]),
        }),
        removeIntake: builder.mutation<IUserSample, {sampleId: number, intakeId: number}>({
            query: (sample) => ({
                url: `${BASE_PATH}/${sample.sampleId}/intake/${sample.intakeId}/remove`,
                method: 'POST',
                body: sample,
            }),
            invalidatesTags: (result, error, arg) =>([{ type: TagTypes.sample, id: arg.sampleId }]),
        }),
        getAllIntakes: builder.query<IPickerSampleIntake[], undefined> ({
            query: () => ({
                url: `${BASE_PATH}/intakes/all`,
            })
        }),
        getUserIntakes: builder.query<{id: number, createdAt: Date, updatedAt: Date}[], number> ({
            query: (userId) => ({
                url: `${BASE_PATH}/user/${userId}/intakes`,
            })
        }),
    }),
});

export default sampleApi;
