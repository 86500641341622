import React from 'react';
import { Text, createStyles } from '@mantine/core';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Label({ children, className }: Props) {
    const { classes, cx } = useStyles();

    return <Text className={cx(classes.label, className)}>{children}</Text>;
}

const useStyles = createStyles((theme) => ({
    label: {
        color: theme.colors.gray[6],
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
        // textTransform: 'uppercase',
        lineHeight: 'initial',
    },
}));
