import React from 'react';
import { Box, Title, Text, Badge, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';

export default function PaperMeta(item: any) {
    const navigate = useNavigate();
    const { classes } = useStyles();

    let createdAt;
    if (item.insights && item.insights.length > 0) {
        createdAt = `Created ${moment(item?.insights[0].created_at).fromNow()}`;
    } else {
        createdAt = false;
    }

    function handleClick() {
        window.open(`https://pubmed.ncbi.nlm.nih.gov/${item._id}`);
    }

    return (
        <Box className={classes.container} onClick={handleClick}>
            <Row>
                <Label className={classes.labelItem}>{item._id}</Label>
            </Row>
            <Title className={classes.title}>{item.paper_title}</Title>
            <Row>
                {item.flagged && (
                    <Badge mr="sm" radius="xs" color="red">
                        Flagged
                    </Badge>
                )}
                <Badge mr="sm" radius="xs" className={classes.defaultBadge}>
                    {item.characteristics.pub_year}
                </Badge>
                <Badge mr="sm" radius="xs" className={classes.defaultBadge}>
                    {item.publisher}
                </Badge>
            </Row>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.blue[0],
        },
    },
    title: {
        marginTop: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
        fontSize: theme.fontSizes.xl,
    },
    abstract: {
        maxHeight: '200px',
        marginTop: theme.spacing.md,
        color: theme.colors.gray[6],
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    labelItem: {
        marginRight: theme.spacing.md,
    },
    createdAt: {
        marginLeft: 'auto',
    },
    editedAt: {
        marginLeft: theme.spacing.md,
    },
    defaultBadge: {
        maxWidth: '300px',
        color: theme.colors.gray[6],
        background: theme.colors.gray[1],
    },
}));
