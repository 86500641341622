import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
    fontFamily: 'Sora, sans-serif',
    headings: {
        fontFamily: 'Sora, sans-serif',
    },
    primaryColor: 'blue',
    colors: {
        blue: [
            '#e3ecf6',
            '#e3ecf6',
            '#c4d6ed',
            '#a8bfe2',
            '#5e7db6',
            '#5e7db6',
            '#5e7db6',
            '#294581',
            '#01235d',
            '#01235d',
        ],
    },
    spacing: {
        xs: '4px',
        sm: '8px',
        md: '16px',
        lg: '24px',
        xl: '48px',
    },
    shadows: {
        md: '0 14px 40px rgb(0 0 0 / 10%)',
    },
    components: {
        Button: {
            styles: (theme) => ({
                root: {
                    fontWeight: 700,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    paddingLeft: '16px',
                    paddingRight: '16px',

                    '&:hover': {
                        background: theme.colors.blue[7],
                        color: 'white',
                    },
                },
                leftIcon: {
                    fontSize: '12px',
                    marginBottom: '1px',
                },
            }),
        },
        Input: {
            styles: (theme) => ({
                input: {
                    height: 'auto',
                    padding: '6px 12px',
                },
            }),
        },
        Textarea: {
            styles: (theme) => ({
                input: {
                    padding: '6px 12px',
                    paddingTop: '12px !important',
                    paddingBottom: '12px !important',
                },
            }),
        },
        InputWrapper: {
            styles: (theme) => ({
                label: {
                    fontWeight: 700,
                    fontSize: theme.fontSizes.xs,
                    color: theme.colors.gray[6],
                    // textTransform: 'uppercase',
                },
            }),
        },
        Tooltip: {
            styles: (theme) => ({
                tooltip: {
                    textAlign: 'center',
                    textTransform: 'initial',
                },
            }),
        },
        Select: {
            styles: (theme) => ({
                input: {
                    textTransform: 'capitalize',
                },
                item: {
                    textTransform: 'capitalize',
                },
            }),
        },
        MultiSelect: {
            styles: (theme) => ({
                input: {
                    cursor: 'pointer',
                },
                value: {
                    padding: `${theme.spacing.md} ${theme.spacing.sm}`,
                    margin: theme.spacing.xs,
                    textTransform: 'capitalize',
                },
                item: {
                    textTransform: 'capitalize',
                },
            }),
        },
        Checkbox: {
            styles: (theme, test) => ({
                body: {
                    alignItems: 'center',
                    height: '48px',
                    padding: theme.spacing.md,
                    borderRadius: '4px',
                    border: `1px solid ${theme.colors.gray[4]}`,
                },
                input: {
                    cursor: 'pointer',
                },
                label: {
                    cursor: 'pointer',
                },
            }),
        },
    },
};

export default theme;
