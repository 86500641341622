import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import Label from './Label';

interface Props {
    label: string;
    to: string | number;
}

export default function BackButton({ to, label }: Props) {
    const { classes } = useStyles();
    const navigate = useNavigate();

    function handleClick() {
        navigate(to as string);
    }

    return (
        <Box className={classes.container} onClick={handleClick}>
            <FontAwesomeIcon className={classes.icon} icon={faAngleLeft} />
            <Label>{label}</Label>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // alignSelf: 'flex-start',
        padding: theme.spacing.sm,
        // marginBottom: theme.spacing.md,
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: '4px',
        background: 'white',
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.blue[0],
        },
    },
    icon: {
        marginRight: theme.spacing.sm,
        color: theme.colors.gray[6],
        fontSize: '10px',
    },
}));
