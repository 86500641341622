import React from 'react';
import { Box, Title, Text, Badge, createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Row from 'components/Row';
import Column from 'components/Column';
import Label from 'components/Label';
import { useAppSelector } from 'hooks';

export default function PaperResultsItem(item: any) {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const version = useAppSelector((state) => state.kb.version);

    let createdAt;
    let editedAt;
    if (!item.insights || item.insights.length === 0) {
        createdAt = false;
        editedAt = false;
    } else {
        if (!item.insights[0].createdAt) {
            createdAt = ``;
        } else {
            createdAt = `Created ${moment(
                item.insights[0].createdAt
            ).fromNow()}`;
        }
        if (!item.insights[0].updatedAt) {
            editedAt = ``;
        } else {
            editedAt = `Edited ${moment(
                item?.insights[item.insights.length - 1].updatedAt
            ).fromNow()}`;
        }
    }

    const status = {
        label: '',
        color: '',
    };
    function getStatus(insights: any) {
        if (insights.length === 0) {
            status.label = 'not started';
            status.color = 'gray';
            return;
        }

        let needsReview = false;
        let validatedFound = false;
        let allOtherArchivedOrDuplicate = true;

        for (let insight of insights) {
            if (insight.status === 'needs review' || !insight.status) {
                needsReview = true;
                break;
            }

            if (insight.status === 'validated') {
                validatedFound = true;
            }

            if (
                insight.status !== 'archived' &&
                insight.status !== 'duplicate' &&
                insight.status !== 'validated'
            ) {
                allOtherArchivedOrDuplicate = false;
            }
        }

        if (needsReview) {
            status.label = 'needs review';
            status.color = 'red';
            return;
        }

        if (validatedFound && allOtherArchivedOrDuplicate) {
            status.label = 'validated';
            status.color = 'blue';
            return;
        } else if (allOtherArchivedOrDuplicate) {
            status.label = 'archived';
            status.color = 'gray';
            return;
        }
    }
    getStatus(item.insights);

    function handleClick() {
        navigate(`/kb/${version}/papers/${item._id}`);
    }

    return (
        <Box className={classes.container} onClick={handleClick}>
            <Row>
                <Label className={classes.labelItem}>
                    {item.journal.impact_factor
                        ? item.journal.impact_factor.toFixed(1)
                        : 0}
                </Label>
                <Label className={classes.labelItem}>{item._id}</Label>
                <Label className={classes.createdAt}>{createdAt}</Label>
                <Label className={classes.editedAt}>{editedAt}</Label>
            </Row>
            <Title className={classes.title}>{item.paper_title}</Title>
            <Row>
                <Badge mr="sm" radius="xs" color={status.color}>
                    {status.label}
                </Badge>
                {/* {createdAt && (
                    <Badge mr="sm" radius="xs">
                        Annotated
                    </Badge>
                )} */}
                {item.flagged && (
                    <Badge mr="sm" radius="xs" color="red">
                        Flagged
                    </Badge>
                )}
                <Badge mr="sm" radius="xs" className={classes.defaultBadge}>
                    {item.characteristics.pub_year}
                </Badge>
                <Badge mr="sm" radius="xs" className={classes.defaultBadge}>
                    {item.publisher}
                </Badge>
            </Row>
            <Text className={classes.abstract}>{item.paper_abstract}</Text>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing.lg,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.blue[0],
        },
    },
    title: {
        marginTop: theme.spacing.sm,
        marginBottom: theme.spacing.sm,
        fontSize: theme.fontSizes.xl,
    },
    abstract: {
        maxHeight: '200px',
        marginTop: theme.spacing.md,
        color: theme.colors.gray[6],
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    labelItem: {
        marginRight: theme.spacing.md,
    },
    createdAt: {
        marginLeft: 'auto',
    },
    editedAt: {
        marginLeft: theme.spacing.md,
    },
    defaultBadge: {
        maxWidth: '300px',
        color: theme.colors.gray[6],
        background: theme.colors.gray[1],
    },
}));
