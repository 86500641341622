import React from 'react';
import { Title, Box, createStyles } from '@mantine/core';

interface Props {
    onClick?: React.MouseEventHandler<HTMLImageElement>;
}

export default function Logo({ onClick }: Props) {
    const { classes } = useStyles();

    return (
        <Box className={classes.container} onClick={onClick}>
            {/* <Title className={classes.title}>PORTAL</Title> */}
            <img
                className={classes.logo}
                src="https://d7cssqfnh2azf.cloudfront.net/brand/Jona_Emblem_Black.png"
                // src="https://d7cssqfnh2azf.cloudfront.net/brand/Jona_Logotype_2023_Black.png"
                alt="logo"
            />
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
        },
    },
    logo: {
        height: '100%',
        width: '24px',
    },
    title: {
        marginBottom: '2px',
        fontSize: '12px',
        fontFamily: 'Sora',
        fontWeight: 700,
    },
}));
