import { Box, Title, Text, createStyles, Flex, Button, Modal, Grid } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../pages/Loading';
import Forbidden from '../../pages/Forbidden';
import providerApi from 'services/provider';
import BackButton from 'components/BackButton';
import { useDisclosure } from '@mantine/hooks';
import DeleteProviderModal from './DeleteProviderModal';

export default function ProviderDetail() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { providerId } = useParams() as { providerId: string };
  const [opened, { close, open, toggle }] = useDisclosure(false);


  if (!providerId) {
    navigate('/providers');
  }

  const { data, isLoading, error } = providerApi.endpoints.getProviderSummary.useQuery({ id: providerId });


  if (error && 'data' in error) {
    if (error.status === 403) {
      return <Forbidden />;
    }
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  function handleEditClick() {
    navigate(`/providers/${providerId}/edit`);
  }

  async function deleteProviderCallback() {
    close();
    navigate('/providers');
  }

  return (
    <Box className={classes.container}>
      <Grid>
        <Grid.Col span={2} mb="md">
          <BackButton to={'/providers'} label="Back" />
        </Grid.Col>
        <Grid.Col span={3} offset={7} mb="md">
        <Flex gap='md'>
            <Button onClick={handleEditClick} variant="outline">
                Edit
            </Button>
            <Button variant="filled" color="red" onClick={toggle}>
                Delete
            </Button>
            <DeleteProviderModal opened={opened} close={close} providerId={providerId} callback={deleteProviderCallback} />
        </Flex>
        </Grid.Col>
      </Grid>

      <Flex direction="column" align="flex-start">
        <Title order={3}>{data.name}</Title>
        <Text>{data.email}</Text>
        <Box>
          <pre>{JSON.stringify(data, null, 3)}</pre>
        </Box>
      </Flex>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    margin: theme.spacing.lg,
  },
  box: {
    width: 'fit-content',
    padding: theme.spacing.md,
    marginRight: theme.spacing.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '4px',
  },
  header: {
    marginBottom: theme.spacing.lg,
  },
  row: {
    cursor: 'pointer',
  },
}));
